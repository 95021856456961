import React, { useState, useEffect } from 'react';

// Mui
import Button from '@material-ui/core/Button';

const ControlChat = () => {
  const [showChat, setShowChat] = useState(false);
  const handleShowChat = () => {
    setShowChat(true);
  };

  useEffect(() => {
    if (showChat) {
      window.Intercom('show');
    }
  }, [showChat]);

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        color="secondary"
        id="live-chat"
        disableElevation
        fullWidth
        onClick={handleShowChat}
      >
        Have Questions? Live Chat
      </Button>
    </div>
  );
};

export default ControlChat;
