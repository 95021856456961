import React from 'react';
import PropTypes from 'prop-types';

// UI components.
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

export default function ModalPrivacy(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="privacy"
      open={open}
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle id="privacy-dialog-title">
        <Typography variant="h4" className="py-4">
          <span className="text-dark font-weight-bold">Privacy Policy</span>
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <div className="text-dark">
            <div className="pb-3">
              <span className="font-weight-bold">Last updated:</span>{' '}
              <span className="font-italic">March 27, 2020</span>
            </div>
            <div className="pb-3">
              This Privacy Policy describes Our policies and procedures on the collection, use and
              disclosure of Your information when You use the Service and tells You about Your
              privacy rights and how the law protects You.
            </div>
            <div className="pb-3">
              We use Your Personal data to provide and improve the Service. By using the Service,
              You agree to the collection and use of information in accordance with this Privacy
              Policy.
            </div>

            <div className="pb-3">Interpretation and Definitions</div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Interpretation</span>
            </Typography>
            <div className="pb-3">
              The words of which the initial letter is capitalized have meanings defined under the
              following conditions.
            </div>
            <div className="pb-3">
              The following definitions shall have the same meaning regardless of whether they
              appear in singular or in plural.
            </div>
            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Definitions</span>
            </Typography>
            <div className="pb-3">
              <div className="pb-3">For the purposes of this Privacy Policy:</div>

              <div className="pb-3">
                <span className="font-weight-bold">You</span> means the individual accessing or
                using the Service, or the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </div>

              <div className="pb-3">
                <span className="font-weight-bold">Company</span> (referred to as either "the
                Company", "We", "Us" or "Our" in this Agreement) refers to Executive Process LLC,
                9210 S. Western Ave Suite A3 Oklahoma City, OK 73139.
              </div>

              <div className="pb-3">
                <ul>
                  <li>
                    <span className="font-weight-bold">Application</span> means the software program
                    provided by the Company downloaded by You on any electronic device, named
                    WhoKnocked
                  </li>
                  <li>
                    <span className="font-weight-bold">Affiliate</span> means an entity that
                    controls, is controlled by or is under common control with a party, where
                    "control" means ownership of 50% or more of the shares, equity interest or other
                    securities entitled to vote for election of directors or other managing
                    authority.
                  </li>
                  <li>
                    <span className="font-weight-bold">Account</span> means a unique account created
                    for You to access our Service or parts of our Service.
                  </li>
                  <li>
                    <span className="font-weight-bold">Website </span> refers to WhoKnocked,
                    accessible from www.whoknocked.com
                  </li>
                  <li>
                    <span className="font-weight-bold">Service</span> refers to the Application or
                    the Website or both.
                  </li>
                  <li>
                    <span className="font-weight-bold">Country</span> refers to: Oklahoma, United
                    States
                  </li>
                </ul>
              </div>

              <div className="pb-3">
                <span className="font-weight-bold">Service Provider </span> means any natural or
                legal person who processes the data on behalf of the Company. It refers to
                third-party companies or individuals employed by the Company to facilitate the
                Service, to provide the Service on behalf of the Company, to perform services
                related to the Service or to assist the Company in analyzing how the Service is
                used.
              </div>

              <div className="pb-3">
                <ul>
                  <li>
                    <span className="font-weight-bold">Third-party Social Media Service </span>
                    refers to any website or any social network website through which a User can log
                    in or create an account to use the Service.
                  </li>
                </ul>
              </div>

              <div className="pb-3">
                <span className="font-weight-bold">Personal Data </span> is any information that
                relates to an identified or identifiable individual.
              </div>

              <div className="pb-3">
                For the purposes of the CCPA, Personal Data means any information that identifies,
                relates to, describes or is capable of being associated with, or could reasonably be
                linked, directly or indirectly, with You.
              </div>

              <div className="pb-3">
                <ul>
                  <li>
                    <span className="font-weight-bold">Cookies</span> are small files that are
                    placed on Your computer, mobile device or any other device by a website,
                    containing the details of Your browsing history on that website among its many
                    uses.
                  </li>
                  <li>
                    <span className="font-weight-bold">Device</span> means any device that can
                    access the Service such as a computer, a cellphone or a digital tablet.
                  </li>
                  <li>
                    <span className="font-weight-bold">Usage Data</span> refers to data collected
                    automatically, either generated by the use of the Service or from the Service
                    infrastructure itself (for example, the duration of a page visit).
                  </li>
                  <li>
                    <span className="font-weight-bold">Do Not Track</span> (DNT) is a concept that
                    has been promoted by US regulatory authorities, in particular the U.S. Federal
                    Trade Commission (FTC), for the Internet industry to develop and implement a
                    mechanism for allowing internet users to control the tracking of their online
                    activities across websites.
                  </li>
                  <li>
                    <span className="font-weight-bold">Business</span>, for the purpose of the CCPA
                    (California Consumer Privacy Act), refers to the Company as the legal entity
                    that collects Consumers' personal information and determines the purposes and
                    means of the processing of Consumers' personal information, or on behalf of
                    which such information is collected and that alone, or jointly with others,
                    determines the purposes and means of the processing of consumers' personal
                    information, that does business in the State of California.
                  </li>
                  <li>
                    <span className="font-weight-bold">Consumer</span>, for the purpose of the CCPA
                    (California Consumer Privacy Act), means a natural person who is a California
                    resident. A resident, as defined in the law, includes (1) every individual who
                    is in the USA for other than a temporary or transitory purpose, and (2) every
                    individual who is domiciled in the USA who is outside the USA for a temporary or
                    transitory purpose.
                  </li>
                  <li>
                    <span className="font-weight-bold">Sale</span>, for the purpose of the CCPA
                    (California Consumer Privacy Act), means selling, renting, releasing,
                    disclosing, disseminating, making available, transferring, or otherwise
                    communicating orally, in writing, or by electronic or other means, a Consumer's
                    Personal information to another business or a third party for monetary or other
                    valuable consideration.
                  </li>
                </ul>
              </div>

              <div className="pb-3">Collecting and Using Your Personal Data</div>

              <Typography variant="h4" gutterBottom>
                <span className="font-weight-bold">Types of Data Collected</span>
              </Typography>

              <Typography variant="h6" gutterBottom>
                Personal Data
              </Typography>
              <div className="pb-3">
                While using Our Service, We may ask You to provide Us with certain personally
                identifiable information that can be used to contact or identify You. Personally
                identifiable information may include, but is not limited to:
              </div>
              <div className="pb-3">
                <ul>
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Address, State, Province, ZIP/Postal code, City</li>
                  <li>Usage Data</li>
                </ul>
              </div>

              <Typography variant="h6" gutterBottom>
                Usage Data
              </Typography>

              <div className="pb-3">
                Usage Data is collected automatically when using the Service.
              </div>
              <div className="pb-3">
                Usage Data may include information such as Your Device's Internet Protocol address
                (e.g. IP address), browser type, browser version, the pages of our Service that You
                visit, the time and date of Your visit, the time spent on those pages, unique device
                identifiers and other diagnostic data.
              </div>
              <div className="pb-3">
                When You access the Service by or through a mobile device, We may collect certain
                information automatically, including, but not limited to, the type of mobile device
                You use, Your mobile device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You use, unique device
                identifiers and other diagnostic data.
              </div>
              <div className="pb-3">
                We may also collect information that Your browser sends whenever You visit our
                Service or when You access the Service by or through a mobile device.
              </div>

              <Typography variant="h6" gutterBottom>
                Information from Third-Party Social Media Services
              </Typography>

              <div className="pb-3">
                The Company allows You to create an account and log in to use the Service through
                the following Third-party Social Media Services:
              </div>
              <div className="pb-3">
                <ul>
                  <li>Google</li>
                  <li>Facebook</li>
                  <li>Twitter</li>
                </ul>
              </div>
              <div className="pb-3">
                If You deconversationIde to register through or otherwise grant us access to a
                Third-Party Social Media Service, We may collect Personal data that is already
                associated with Your Third-Party Social Media Service's account, such as Your name,
                Your email address, Your activities or Your contact list associated with that
                account.
              </div>
              <div className="pb-3">
                You may also have the option of sharing additional information with the Company
                through Your Third-Party Social Media Service's account. If You choose to provide
                such information and Personal Data, during registration or otherwise, You are giving
                the Company permission to use, share, and store it in a manner consistent with this
                Privacy Policy.
              </div>

              <Typography variant="h6" gutterBottom>
                Information Collected while Using the Application
              </Typography>
              <div className="pb-3">
                While using Our Application, in order to provide features of Our Application, We may
                collect, with your prior permission:
              </div>
              <div className="pb-3">
                <ul>
                  <li>Information regarding your location</li>
                  <li>Information from your Device's phone book (contacts list)</li>
                  <li>
                    Pictures and other information from your Device's camera and photo library
                  </li>
                </ul>
              </div>
              <div className="pb-3">
                We use this information to provide features of Our Service, to improve and customize
                Our Service. The information may be uploaded to the Company's servers and/or a
                Service Provider's server or it be simply stored on Your device.
              </div>
              <div className="pb-3">
                You can enable or disable access to this information at any time, through Your
                Device settings. You can also enable or disable location services when You use Our
                Service at any time, through Your Device settings.
              </div>

              <Typography variant="h6" gutterBottom>
                Tracking Technologies and Cookies
              </Typography>
              <div className="pb-3">
                We use Cookies and similar tracking technologies to track the activity on Our
                Service and store certain information. Tracking technologies used are beacons, tags,
                and scripts to collect and track information and to improve and analyze Our Service.
              </div>
              <div className="pb-3">
                You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is
                being sent. However, if You do not accept Cookies, You may not be able to use some
                parts of our Service.
              </div>
              <div className="pb-3">
                Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your
                personal computer or mobile device when You go offline, while Session Cookies are
                deleted as soon as You close your web browser. Learn more about cookies:{' '}
                <a
                  href="https://www.termsfeed.com/blog/cookies/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-info"
                >
                  All About Cookies
                </a>
                .
              </div>
              <div className="pb-3">
                We use both session and persistent Cookies for the purposes set out below:
              </div>

              <Typography variant="subtitle1" className="font-weight-bold pb-3">
                Necessary / Essential Cookies
              </Typography>

              <div className="pb-3">Type: Session Cookies</div>

              <div className="pb-3">Administered by: Us</div>

              <div className="pb-3">
                Purpose: These Cookies are essential to provide You with services available through
                the Website and to enable You to use some of its features. They help to authenticate
                users and prevent fraudulent use of user accounts. Without these Cookies, the
                services that You have asked for cannot be provided, and We only use these Cookies
                to provide You with those services.
              </div>

              <Typography variant="subtitle1" className="font-weight-bold pb-3">
                Cookies Policy / Notice Acceptance Cookies
              </Typography>

              <div className="pb-3">Type: Persistent Cookies</div>

              <div className="pb-3">Administered by: Us</div>

              <div className="pb-3">
                Purpose: These Cookies identify if users have accepted the use of cookies on the
                Website.
              </div>

              <Typography variant="subtitle1" className="font-weight-bold pb-3">
                Functionality Cookies
              </Typography>

              <div className="pb-3">Type: Persistent Cookies</div>

              <div className="pb-3">Administered by: Us</div>

              <div className="pb-3">
                Purpose: These Cookies allow us to remember choices You make when You use the
                Website, such as remembering your login details or language preference. The purpose
                of these Cookies is to provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the Website.
              </div>

              <Typography variant="subtitle1" className="font-weight-bold pb-3">
                Tracking and Performance Cookies
              </Typography>

              <div className="pb-3">Type: Persistent Cookies</div>

              <div className="pb-3">Administered by: Third-Parties</div>

              <div className="pb-3">
                Purpose: These Cookies are used to track information about traffic to the Website
                and how users use the Website. The information gathered via these Cookies may
                directly or indirectly identify you as an individual visitor. This is because the
                information collected is typically linked to a pseudonymous identifier associated
                with the device you use to access the Website. We may also use these Cookies to test
                new advertisements, pages, features or new functionality of the Website to see how
                our users react to them.
              </div>

              <div className="pb-3">
                For more information about the cookies we use and your choices regarding cookies,
                please visit our Cookies Policy.
              </div>
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Use of Your Personal Data</span>
            </Typography>
            <div className="pb-3">
              <div className="pb-3">
                The Company may use Personal Data for the following purposes:
              </div>

              <ul>
                <li>
                  <span className="font-weight-bold">To provide and maintain our Service</span>,
                  including to monitor the usage of our Service.
                </li>
                <li>
                  <span className="font-weight-bold">To manage Your Account</span>: to manage Your
                  registration as a user of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are available to You as a
                  registered user.
                </li>
                <li>
                  <span className="font-weight-bold">For the performance of a contract</span>: the
                  development, compliance and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract with Us through the
                  Service.
                </li>
                <li>
                  <span className="font-weight-bold">To contact You</span>: To contact You by email,
                  telephone calls, SMS, or other equivalent forms of electronic communication, such
                  as a mobile application's push notifications regarding updates or informative
                  communications related to the functionalities, products or contracted services,
                  including the security updates, when necessary or reasonable for their
                  implementation.
                </li>
                <li>
                  <span className="font-weight-bold">To provide You with</span> news, special offers
                  and general information about other goods, services and events which we offer that
                  are similar to those that you have already purchased or enquired about unless You
                  have opted not to receive such information.
                </li>
                <li>
                  <span className="font-weight-bold">To manage Your requests</span>: To attend and
                  manage Your requests to Us.
                </li>
              </ul>

              <div className="pb-3">
                We may share your personal information in the following situations:
              </div>

              <ul>
                <li>
                  <span className="font-weight-bold">With Service Providers</span>: We may share
                  Your personal information with Service Providers to monitor and analyze the use of
                  our Service, to show advertisements to You to help support and maintain Our
                  Service, to contact You, to advertise on third party websites to You after You
                  visited our Service or for payment processing.
                </li>
                <li>
                  <span className="font-weight-bold">For Business transfers</span>: We may share or
                  transfer Your personal information in connection with, or during negotiations of,
                  any merger, sale of Company assets, financing, or acquisition of all or a portion
                  of our business to another company.
                </li>
                <li>
                  <span className="font-weight-bold">With Affiliates</span>: We may share Your
                  information with Our affiliates, in which case we will require those affiliates to
                  honor this Privacy Policy. Affiliates include Our parent company and any other
                  subsidiaries, joint venture partners or other companies that We control or that
                  are under common control with Us.
                </li>
                <li>
                  <span className="font-weight-bold">With Business partners</span>: We may share
                  Your information with Our business partners to offer You certain products,
                  services or promotions.
                </li>
                <li>
                  <span className="font-weight-bold">With other users</span>: when You share
                  personal information or otherwise interact in the public areas with other users,
                  such information may be viewed by all users and may be publicly distributed
                  outside. If You interact with other users or register through a Third-Party Social
                  Media Service, Your contacts on the Third-Party Social Media Service may see Your
                  name, profile, pictures and description of Your activity. Similarly, other users
                  will be able to view descriptions of Your activity, communicate with You and view
                  Your profile.
                </li>
              </ul>
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Retention of Your Personal Data</span>
            </Typography>
            <div className="pb-3">
              The Company will retain Your Personal Data only for as long as is necessary for the
              purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
              the extent necessary to comply with our legal obligations (for example, if we are
              required to retain your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </div>
            <div className="pb-3">
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is
              generally retained for a shorter period of time, except when this data is used to
              strengthen the security or to improve the functionality of Our Service, or We are
              legally obligated to retain this data for longer time periods.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Transfer of Your Personal Data</span>
            </Typography>
            <div className="pb-3">
              Your information, including Personal Data, is processed at the Company's operating
              offices and in any other places where the parties involved in the processing are
              located. It means that this information may be transferred to — and maintained on —
              computers located outside of Your state, province, country or other governmental
              jurisdiction where the data protection laws may differ than those from Your
              jurisdiction.
            </div>
            <div className="pb-3">
              Your consent to this Privacy Policy followed by Your submission of such information
              represents Your agreement to that transfer.
            </div>
            <div className="pb-3">
              The Company will take all steps reasonably necessary to ensure that Your data is
              treated securely and in accordance with this Privacy Policy and no transfer of Your
              Personal Data will take place to an organization or a country unless there are
              adequate controls in place including the security of Your data and other personal
              information.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Disclosure of Your Personal Data</span>
            </Typography>

            <Typography variant="h6" gutterBottom>
              Business Transactions
            </Typography>

            <div className="pb-3">
              If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
              may be transferred. We will provide notice before Your Personal Data is transferred
              and becomes subject to a different Privacy Policy.
            </div>

            <Typography variant="h6" gutterBottom>
              Law enforcement
            </Typography>

            <div className="pb-3">
              Under certain circumstances, the Company may be required to disclose Your Personal
              Data if required to do so by law or in response to valid requests by public
              authorities (e.g. a court or a government agency).
            </div>

            <Typography variant="h6" gutterBottom>
              Other legal requirements
            </Typography>

            <div className="pb-3">
              The Company may disclose Your Personal Data in the good faith belief that such action
              is necessary to:
            </div>
            <div className="pb-3">
              <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
              </ul>
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Security of Your Personal Data</span>
            </Typography>
            <div className="pb-3">
              The security of Your Personal Data is important to Us, but remember that no method of
              transmission over the Internet, or method of electronic storage is 100% secure. While
              We strive to use commercially acceptable means to protect Your Personal Data, We
              cannot guarantee its absolute security.
            </div>
            <div className="pb-3">Detailed Information on the Processing of Your Personal Data</div>
            <div className="pb-3">
              Service Providers have access to Your Personal Data only to perform their tasks on Our
              behalf and are obligated not to disclose or use it for any other purpose.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Analytics</span>
            </Typography>
            <div className="pb-3">
              We may use third-party Service providers to monitor and analyze the use of our
              Service.
            </div>
            <div className="pb-3">
              <span className="font-weight-bold">Google Analytics</span>
            </div>
            <div className="pb-3">
              Google Analytics is a web analytics service offered by Google that tracks and reports
              website traffic. Google uses the data collected to track and monitor the use of our
              Service. This data is shared with other Google services. Google may use the collected
              data to contextualise and personalise the ads of its own advertising network.
            </div>
            <div className="pb-3">
              For more information on the privacy practices of Google, please visit the Google
              Privacy Terms web page: https://policies.google.com/privacy?hl=en
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Email Marketing</span>
            </Typography>
            <div className="pb-3">
              We may use Your Personal Data to contact You with newsletters, marketing or
              promotional materials and other information that may be of interest to You. You may
              opt-out of receiving any, or all, of these communications from Us by following the
              unsubscribe link or instructions provided in any email We send or by contacting Us.
            </div>
            <div className="pb-3">
              We may use Email Marketing Service Providers to manage and send emails to You.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Usage, Performance and Miscellaneous</span>
            </Typography>
            <div className="pb-3">
              We may use third-party Service Providers to provide better improvement of our Service.
            </div>
            <div className="pb-3">
              <span className="font-weight-bold">Invisible reCAPTCHA</span>
            </div>
            <div className="pb-3">
              We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google.
            </div>
            <div className="pb-3">
              The reCAPTCHA service may collect information from You and from Your Device for
              security purposes.
            </div>
            <div className="pb-3">
              The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of
              Google:{' '}
              <a
                href="https://www.google.com/intl/en/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-info"
              >
                https://www.google.com/intl/en/policies/privacy/
              </a>
            </div>
            <div className="pb-3">CCPA Privacy</div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Your Rights under the CCPA</span>
            </Typography>
            <div className="pb-3">
              Under this Privacy Policy, and by law if You are a resident of California, You have
              the following rights:
              <ul>
                <li>
                  The right to notice. You must be properly notified which categories of Personal
                  Data are being collected and the purposes for which the Personal Data is being
                  used.
                </li>
                <li>
                  The right to access / the right to request. The CCPA permits You to request and
                  obtain from the Company information regarding the disclosure of Your Personal Data
                  that has been collected in the past 12 months by the Company or its subsidiaries
                  to a third-party for the third party's direct marketing purposes.
                </li>
                <li>
                  The right to say no to the sale of Personal Data. You also have the right to ask
                  the Company not to sell Your Personal Data to third parties. You can submit such a
                  request by visiting our "Do Not Sell My Personal Information" section or web page.
                </li>
                <li>
                  The right to know about Your Personal Data. You have the right to request and
                  obtain from the Company information regarding the disclosure of the following:
                </li>

                <ul>
                  <li>The categories of Personal Data collected</li>
                  <li>The sources from which the Personal Data was collected</li>
                  <li>
                    The business or commercial purpose for collecting or selling the Personal Data
                  </li>
                  <li>Categories of third parties with whom We share Personal Data</li>
                  <li>The specific pieces of Personal Data we collected about You</li>
                </ul>

                <li>
                  The right to delete Personal Data. You also have the right to request the deletion
                  of Your Personal Data that have been collected in the past 12 months.
                </li>
                <li>
                  The right not to be discriminated against. You have the right not to be
                  discriminated against for exercising any of Your Consumer's rights, including by:
                </li>

                <ul>
                  <li>Denying goods or services to You</li>
                  <li>
                    Charging different prices or rates for goods or services, including the use of
                    discounts or other benefits or imposing penalties
                  </li>
                  <li>Providing a different level or quality of goods or services to You</li>
                  <li>
                    Suggesting that You will receive a different price or rate for goods or services
                    or a different level or quality of goods or services.
                  </li>
                </ul>
              </ul>
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Exercising Your CCPA Data Protection Rights</span>
            </Typography>
            <div className="pb-3">
              In order to exercise any of Your rights under the CCPA, and if you are a California
              resident, You can email or call us or visit our "Do Not Sell My Personal Information"
              section or web page.
            </div>
            <div className="pb-3">
              The Company will disclose and deliver the required information free of charge within
              45 days of receiving Your verifiable request. The time period to provide the required
              information may be extended once by an additional 45 days when reasonable necessary
              and with prior notice.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Do Not Sell My Personal Information</span>
            </Typography>
            <div className="pb-3">
              We do not sell personal information. However, the Service Providers we partner with
              (for example, our advertising partners) may use technology on the Service that "sells"
              personal information as defined by the CCPA law.
            </div>
            <div className="pb-3">
              If you wish to opt out of the use of your personal information for interest-based
              advertising purposes and these potential sales as defined under CCPA law, you may do
              so by following the instructions below.
            </div>
            <div className="pb-3">
              Please note that any opt out is specific to the browser You use. You may need to opt
              out on every browser that you use.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Website</span>
            </Typography>
            <div className="pb-3">
              You can opt out of receiving ads that are personalized as served by our Service
              Providers by following our instructions presented on the Service:
            </div>
            <div className="pb-3">
              <ul>
                <li>From Our "Cookie Consent" notice banner</li>
                <li>Or from Our "CCPA Opt-out" notice banner</li>
                <li>Or from Our "Do Not Sell My Personal Information" notice banner</li>
                <li>Or from Our "Do Not Sell My Personal Information" link</li>
              </ul>
            </div>
            <div className="pb-3">
              The opt out will place a cookie on Your computer that is unique to the browser You use
              to opt out. If you change browsers or delete the cookies saved by your browser, you
              will need to opt out again.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Mobile Devices</span>
            </Typography>
            <div className="pb-3">
              Your mobile device may give you the ability to opt out of the use of information about
              the apps you use in order to serve you ads that are targeted to your interests:
            </div>
            <div className="pb-3">
              <ul>
                <li>
                  "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android
                  devices
                </li>
                <li>"Limit Ad Tracking" on iOS devices</li>
              </ul>
            </div>
            <div className="pb-3">
              You can also stop the collection of location information from Your mobile device by
              changing the preferences on your mobile device.
            </div>
            <div className="pb-3">
              "Do Not Track" Policy as Required by California Online Privacy Protection Act
              (CalOPPA)
            </div>
            <div className="pb-3">Our Service does not respond to Do Not Track signals.</div>
            <div className="pb-3">
              However, some third party websites do keep track of Your browsing activities. If You
              are visiting such websites, You can set Your preferences in Your web browser to inform
              websites that You do not want to be tracked. You can enable or disable DNT by visiting
              the preferences or settings page of Your web browser.
            </div>
            <div className="pb-3">
              <span className="font-weight-bold">Children's Privacy</span>
            </div>
            <div className="pb-3">
              Our Service does not address anyone under the age of 13. We do not knowingly collect
              personally identifiable information from anyone under the age of 13. If You are a
              parent or guardian and You are aware that Your child has provided Us with Personal
              Data, please contact Us. If We become aware that We have collected Personal Data from
              anyone under the age of 13 without verification of parental consent, We take steps to
              remove that information from Our servers.
            </div>
            <div className="pb-3">
              We also may limit how We collect, use, and store some of the information of Users
              between 13 and 18 years old. In some cases, this means We will be unable to provide
              certain functionality of the Service to these users.
            </div>
            <div className="pb-3">
              If We need to rely on consent as a legal basis for processing Your information and
              Your country requires consent from a parent, We may require Your parent's consent
              before We collect and use that information.
            </div>
            <div className="pb-3">
              <span className="font-weight-bold">Links to Other Websites</span>
            </div>
            <div className="pb-3">
              Our Service may contain links to other websites that are not operated by Us. If You
              click on a third party link, You will be directed to that third party's site. We
              strongly advise You to review the Privacy Policy of every site You visit.
            </div>
            <div className="pb-3">
              We have no control over and assume no responsibility for the content, privacy policies
              or practices of any third party sites or services.
            </div>
            <div className="pb-3">
              <span className="font-weight-bold">Changes to this Privacy Policy</span>
            </div>
            <div className="pb-3">
              We may update our Privacy Policy from time to time. We will notify You of any changes
              by posting the new Privacy Policy on this page.
            </div>
            <div className="pb-3">
              We will let You know via email and/or a prominent notice on Our Service, prior to the
              change becoming effective and update the "Last updated" date at the top of this
              Privacy Policy.
            </div>
            <div className="pb-3">
              You are advised to review this Privacy Policy periodically for any changes. Changes to
              this Privacy Policy are effective when they are posted on this page.
            </div>
            <div className="pb-3">
              <span className="font-weight-bold">Contact Us</span>
            </div>
            <div className="pb-3">
              If you have any questions about this Privacy Policy, You can contact us:
            </div>
            <div className="pb-3">
              <ul>
                <li>By email: info@whoknocked.com</li>
                <li>By visiting this page on our website: www.whoknocked.com</li>
              </ul>
            </div>
          </div>
        </Typography>
        <Divider />
      </DialogContent>

      <DialogActions>
        <div className="p-4">
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ModalPrivacy.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
