// Utilities.
import { titleCase } from "title-case";

/*
Handles formatting a Process Server's slug into a name that can be displayed.
 */
export default function formatNameFromSlug(slug) {
  let slugStr = slug.trim();
  slugStr = slugStr.toLowerCase();
  slugStr = slugStr.replace(".", " ") + ".";
  slugStr = titleCase(slugStr);

  return slugStr;
}
