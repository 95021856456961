import React from "react";

// Mui
import { Skeleton } from "@material-ui/lab";

export default class LoaderSkeleton extends React.Component {
  render() {
    if (!this.props.loaded) {
      // You can render any custom fallback UI
      return (
        <Skeleton className="component-loader-skeleton">
          {this.props.children}
        </Skeleton>
      );
    }
    return this.props.children;
  }
}
