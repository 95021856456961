import React from "react";

// Components
import ControlChat from "../components/ControlChat";
import NavHeaderTitle from "../components/NavHeaderTitle";

// Mui
import Grid from "@material-ui/core/Grid";

export default function ConstrPublicChat() {
  return (
    <div className="containerView">
      <NavHeaderTitle title={"Chat"} />
      <Grid
        container
        direction="column"
        spacing={3}
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} className="text-center pt-4 mt-4">
          <div>
            <ControlChat />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
