/*
Material-UI theme overrides.
 */

import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    text: {
      primary: "#FFFFF",
    },
    primary: {
      main: "#f2f4f6",
      contrastText: "#666666",
      text: "#000000",
    },
    secondary: {
      main: "#26aae1",
      contrastText: "#FFFFFF",
      text: "#FFFFFF",
      background: "#26aae1",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  shadows: ["none"],
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiGrid: {
      item: {
        width: "100%",
        textAlign: "center",
      },
    },
    MuiTextField: {
      root: {
        borderColor: "white",
        backgroundColor: "white",
        color: "#666666",
        "&:active": {
          backgroundColor: "white",
        },
        "&:focus": {
          backgroundColor: "white",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#666666",
        backgroundColor: "white",
      },
      colorSecondary: {
        color: "white",
        background: "transparent",
      },
      colorDisabled: {
        color: "white",
        filter: "brightness(80%)",
        background: "transparent",
        cursor: "not-allowed",
        "&:hover": {
          filter: "brightness(80%)",
          cursor: "not-allowed",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#FFFFFF",
        color: "#000000",
        borderRadius: "10px",
      },
      arrow: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      disableElevation: true,
      label: {
        color: "#FFFFFF",
      },
      outlined: {
        borderColor: "#FFFFFF",
      },
      containedPrimary: {
        backgroundColor: "#BBBBBB",
      },
      text: {
        padding: "0px",
        minWidth: "1px",
        maxWidth: "3px",
        marginLeft: "5px",
        marginRight: "5px",
        "&:hover": {
          borderColor: "transparent",
          border: "0",
        },
        "&:focus": {
          borderColor: "transparent",
          border: "0",
        },
        "&:active": {
          borderColor: "transparent",
          border: "0",
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "white",
        },
        "&:focus": {
          backgroundColor: "white",
        },
        "&:active": {
          backgroundColor: "white",
        },
        Mui: {
          focused: {
            backgroundColor: "white",
          },
        },
      },
      underline: {
        borderBottom: "white",
        "&:after:": {
          backgroundColor: "white",
          borderBottom: "white",
          transition: "none",
        },
      },
      input: {
        backgroundColor: "white",
      },
      adornedEnd: {
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "white",
        },
        "&:focus": {
          backgroundColor: "white",
        },
      },
      marginDense: {
        backgroundColor: "white",
        "&:focus": {
          backgroundColor: "white",
        },
        "&:active": {
          backgroundColor: "white",
        },
      },
    },
    MuiTypography: {
      h4: {
        fontWeight: "bold",
      },
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: "#bababa",
        borderBottom: "0px solid rgba(224, 224, 224, 1)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
          minHeight: 56,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        backgroundColor: "#bababa",
        borderBottom: "0px solid #bababa",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
          minHeight: 56,
        },
      },
    },
    MuiTableContainer: {
      root: {
        borderRadius: "10px",
        border: "1px solid rgba(186, 186, 186, .5)",
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "rgba(186, 186, 186, .8)",
      },
    },
    MuiTableRow: {
      root: {
        borderTop: "1px solid rgba(186, 186, 186, .4)",
      },
    },
    MuiBadge: {
      colorPrimary: {
        color: "white",
        backgroundColor: "#bababa",
      },
    },
    MuiAlert: {
      root: {
        color: "white",
        boxShadow: "none",
      },
      filledError: {
        color: "white",
      },
      filledSuccess: {
        color: "white",
      },
      icon: {
        color: "white",
        backgroundColor: "transparent",
      },
      message: {
        color: "white",
        backgroundColor: "transparent",
        textAlign: "left",
      },
      action: {
        color: "white",
        backgroundColor: "transparent",
      },
      standardError: {
        color: "rgb(97, 26, 21)",
        icon: {
          fontSize: "23px",
        },
      },
    },
    MuiSkeleton: {
      root: {
        color: "#26aae1",
        backgroundColor: "#26aae1",
        filter: "brightness(91%)",
        opacity: "0.4",
      },
      pulse: {
        "&:after": {
          animation: "MuiSkeleton-keyframes-wave 1.6s linear 0.5s infinite",
        },
      },
    },
    MuiCard: {
      root: {
        backgroundColor: "#f2f4f6",
      },
    },
    MuiListItemText: {
      primary: {
        textAlign: "left",
        color: "inherit",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        lineHeight: "inherit",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "30px",
        color: "inherit",
        backgroundColor: "transparent",
      },
    },
  },
});

export default theme;
