import React from "react";

// Components
import FormAuthenticate from "../components/FormAuthenticate";

// Mui

export default class ConstrLandingAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: props.loaded,
      error: props.error,
    };
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    return (
      <div className="root data-constructor">
        <FormAuthenticate error={this.state.error} loaded={this.state.loaded} />
      </div>
    );
  }
}
