import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import LoaderSkeleton from './LoaderSkeleton';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const muiStyles = () => ({
  audio: {
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  dialogActions: {},
});

const ConfirmEndConversation = props => {
  const { classes, handleClose, handleConfirm, open, title } = props;
  const handleYes = () => {
    handleClose();
    handleConfirm();
  };
  return (
    <LoaderSkeleton loaded={true}>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <div className="w-100 h-100 bg-light text-dark d-flex flex-column justify-content-center align-items-center border-0 p-2 px-5">
          <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        </div>
        <DialogActions>
          <Grid
            container
            justify="space-between"
            wrap="nowrap"
            spacing="2"
            className={classes.dialogActions}
          >
            <Grid item xs={6}>
              <Button
                key="YES"
                size="medium"
                color="secondary"
                variant="contained"
                onClick={handleYes}
                fullWidth
              >
                YES
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                key="NO"
                size="medium"
                color="secondary"
                variant="contained"
                onClick={handleClose}
                fullWidth
              >
                NO
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </LoaderSkeleton>
  );
};

export default withStyles(muiStyles)(ConfirmEndConversation);
