import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default class SnackbarError extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      loaded: true,
      error: props.error,
      haveRequired: true,
    };
  }

  componentDidMount() {}

  handleClickToggle = () => {
    this.props.updateSnackbar(false);
  };

  render() {
    return (
      <span className="components fixed-top">
        <Snackbar
          open={this.props.open}
          onClose={this.handleClickToggle}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={{ vertical: "top", horizontal: "center" }}
          className="fixed-top"
        >
          <div className="p-1 text-uppercase font-weight-light">
            <Alert
              onClose={this.handleClickToggle}
              variant="filled"
              severity="error"
            >
              {this.props.errorMsg}
            </Alert>
          </div>
        </Snackbar>
      </span>
    );
  }
}
