import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import LoaderSkeleton from './LoaderSkeleton';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const DisplayTranscription = ({ transcription, loaded, open, onClose }) => {
  return (
    <LoaderSkeleton loaded={true}>
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
        <div className="w-100 h-100 bg-light text-dark d-flex flex-column justify-content-center align-items-center border-0 p-2 px-5">
          <DialogTitle id="simple-dialog-title">Transcription Text</DialogTitle>
          <DialogContent>
            <Typography align="center">
              {loaded ? transcription : <CircularProgress color="secondary" />}
            </Typography>
          </DialogContent>
        </div>
      </Dialog>
    </LoaderSkeleton>
  );
};

export default DisplayTranscription;
