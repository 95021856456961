import React from "react";
import { withRouter } from "react-router-dom";

// UI Components.
import Button from "@material-ui/core/Button";
import UtilErrorBoundary from "../wrappers/WrErrorBoundary";

class NavBackBtn extends React.Component {
  render() {
    return (
      <UtilErrorBoundary>
        <div>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            disableElevation
            fullWidth
            onClick={() => this.props.history.goBack()}
          >
            Go Back
          </Button>
        </div>
      </UtilErrorBoundary>
    );
  }
}

export default withRouter(NavBackBtn);
