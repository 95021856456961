import moment from "moment";

export default function getPrettyDate(str, notime, sentenceReady) {
  let finalStr = "";

  if (notime) {
    finalStr = moment(str).format("MM/DD/YY");
  } else {
    finalStr = moment(str).format("MM/DD/YY h:mm A");
  }

  if (sentenceReady) {
    finalStr = moment(finalStr).format("dddd, MMMM Do YYYY");
  }

  return finalStr;
}
