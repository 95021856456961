import React, { useState, useContext } from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import LoaderSkeleton from './LoaderSkeleton';

import { withStyles } from '@material-ui/core/styles';
// Context
import ContextEndpoint from '../context/ContextEndpoint';
//components
import DisplayTranscription from './DisplayTranscription';
import DisplayChip from './DisplayChip';

const muiStyles = () => ({
  audio: {
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  trascribeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    top: 10,
    '&:focus': {
      outline: 'none',
    },
  },
});

const DisplayDialog = props => {
  const classes = props.classes;
  const [transcription, setTranscription] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [transcriptionPanelOpen, setTransciptionPanelOpen] = useState(false);
  const context = useContext(ContextEndpoint);

  const recordingUrl =
    props.RecordingUrl && props.RecordingUrl.split('.')[1] === 'json'
      ? `https://api.twilio.com${props.RecordingUrl.slice(0, props.RecordingUrl.length - 5)}`
      : props.RecordingUrl;

  const handleClickToggle = () => {
    props.updateActive(false);
  };

  const getTranscriptionText = () => {
    setTransciptionPanelOpen(true);
    setLoaded(false);
    fetch(
      `${context.url}/api/transcription/get?recordingUrl=${recordingUrl}&authorization=${localStorage.whoknocked_token}`
    )
      .then(r => {
        return r.json();
      })
      .then(result => {
        setTranscription(result.data.text);
        setLoaded(true);
      })
      .catch(e => {});
  };

  return (
    <LoaderSkeleton loaded={true}>
      <>
        {
          <Dialog
            onClose={handleClickToggle}
            aria-labelledby="simple-dialog-title"
            open={props.open}
          >
            <div className="w-100 h-100 bg-light text-dark d-flex flex-column justify-content-center align-items-center border-0 p-2 px-5">
              <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
              <audio controls className={classes.audio}>
                {/* <source src={props.RecordingUrl} />
                 */}
                <source src={recordingUrl} />
              </audio>
              <DialogActions>
                <div className="data-footer w-100 d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex flex-row align-items-center justify-content-end data data-container">
                    <DisplayChip
                      labelType={`text`}
                      labelFormat={`static`}
                      labelVal={`Fetch Transcription`}
                      displayType={`icon`}
                      displayVal={`transcription`}
                      color={`dark`}
                      clickable={true}
                      loaded={true}
                      error={false}
                      onClick={getTranscriptionText}
                    />
                  </div>
                </div>
              </DialogActions>
            </div>
          </Dialog>
        }
        <DisplayTranscription
          loaded={loaded}
          transcription={transcription}
          onClose={() => {
            setTransciptionPanelOpen(false);
            setTranscription(null);
          }}
          open={transcriptionPanelOpen}
        />
      </>
    </LoaderSkeleton>
  );
};

export default withStyles(muiStyles)(DisplayDialog);
