import React from 'react';

// Mui
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const muiStyles = () => ({
  btn: {
    '@media (max-width:768px)': {
      marginBottom: 300,
    },
  },
});

const BtnStaticNoPhone = ({ slug, classes }) => {
  return (
    <Button variant="outlined" size="large" href={`/${slug}/more-info`} className={classes.btn}>
      <Typography variant="body2" className="p-1">
        I don’t want to provide my phone number
      </Typography>
    </Button>
  );
};

export default withStyles(muiStyles)(BtnStaticNoPhone);
