import React from 'react';

// Components
import BtnStaticHowWeProtect from '../components/BtnStaticHowWeProtect';
import ControlChat from '../components/ControlChat';
import BtnEnterPhone from '../components/BtnStaticEnterPhone';

// Mui
import Grid from '@material-ui/core/Grid';
import NavHeaderSubTitle from '../components/NavHeaderSubTitle';
import NavHeaderTitle from '../components/NavHeaderTitle';
//styles
import { withStyles } from '@material-ui/core/styles';

//Mui styles
const muiStyles = () => ({
  section: {
    margin: '30px 0',
  },
});

const ConsumerMoreInfo = ({ slug, classes }) => {
  return (
    <div className="w-100">
      <NavHeaderTitle title={'Concerned about privacy and security?'} />
      <Grid container direction="column" justify="space-evenly" alignItems="center">
        <Grid item xs={10} sm={8} md={10} lg={8} xl={8} className={classes.section}>
          <NavHeaderSubTitle
            subtitle={`We take your security and privacy seriously. You do not have to
                            provide any personal details to your process server, and no information
                            is shared with any other party.`}
          />
        </Grid>

        <Grid item xs={10} sm={8} md={10} lg={8} xl={8} className={classes.section}>
          <Grid container direction="row" spacing={5} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <BtnStaticHowWeProtect />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <ControlChat />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={10} sm={8} md={10} lg={8} xl={8} className={classes.section}>
          <BtnEnterPhone slug={slug} />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(muiStyles)(ConsumerMoreInfo);
