// Utils
import getPrettyDate from "../utils/getPrettyDate";
import getPrettyTxt from "../utils/getPrettyTxt";
import getPrettyPhone from "../utils/getPrettyPhone";

export default function HelperTxtByType(format, str, args) {
  try {
    // Format text used in components based on type (i.e. data, phone, title, etc.)
    switch (format) {
      case "date":
        return getPrettyDate(str, args.notime, args.sentenceReady);
      case "phone":
        return getPrettyPhone(str);
      // Text
      case "title":
        return getPrettyTxt(str);
      case "long":
        return str.slice(0, args.maxLength);
      // Status
      case "status":
        if (str || str === "true") {
          return "Active";
        } else {
          return "Ended";
        }
      case "present":
        if (str.length > 0 && str !== "null") {
          return "On File";
        } else {
          return "Missing";
        }
      default:
        return str;
    }
  } catch {
    return str;
  }
}
