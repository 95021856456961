import React from 'react';

const ContextEndpoint = React.createContext({
  url: `http://whoknocked.com`,
});

export const ContextEndpointProvider = ContextEndpoint.Provider;
export const ContextEndpointConsumer = ContextEndpoint.Consumer;

export default ContextEndpoint;
