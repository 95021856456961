import React from "react";

// Constructor/s
import ConstrPublicBadSlug from "../constructors/ConstrPublicBadSlug";

// Components

export default class ViewPublicBadSlug extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
    };
  }

  render() {
    return <ConstrPublicBadSlug />;
  }
}
