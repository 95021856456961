/*
View consumers see after successfully connecting with their Process Server via Twilio SMS.
 */
import React from "react";
import { Link } from "react-router-dom";

// Utils
import getPrettyPhone from "../utils/getPrettyPhone";

// UI components.
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

// Responsive.
import Hidden from "@material-ui/core/Hidden";
import NavHeaderTitle from "../components/NavHeaderTitle";

export default class ConstrPublicConnected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: props.slug,
      status: "",
      conversationPhone: getPrettyPhone(props.conversationPhone),
      error: "",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="containerView">
        <NavHeaderTitle title={"You are now connected!"} />
        <Grid
          container
          direction="column"
          spacing={3}
          justify="flex-start"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="text-center pt-4 mt-4"
          >
            <Hidden lgUp>
              <Typography variant="body1" gutterBottom>
                <div>
                  You will receive a text message connecting you and your
                  Process Server.
                </div>
                <div>It can take up to five minutes.</div>
                <div>
                  If you do not receive a text message, please{" "}
                  <Link
                    to={`/${this.state.slug}/more-info`}
                    className="text-white"
                  >
                    click here
                  </Link>
                  .
                </div>
                <Typography variant="subtitle1" gutterBottom>
                  {this.state.conversationPhone ? (
                    <div className="text-center py-4 my-4">
                      Secure SMS number: {this.state.conversationPhone}
                    </div>
                  ) : (
                    <div className="text-center py-4 my-4">
                      <CircularProgress color="primary" />
                    </div>
                  )}
                </Typography>
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h6" gutterBottom>
                <div>
                  You will receive a text message connecting you and your
                  Process Server.
                </div>
                <div>It can take up to five minutes.</div>
                <div>
                  If you do not receive a text message, please{" "}
                  <Link
                    to={`/${this.state.slug}/more-info`}
                    className="text-white"
                  >
                    click here
                  </Link>
                  .
                </div>
                <Typography variant="subtitle1" gutterBottom>
                  {this.state.conversationPhone ? (
                    <div className="text-center py-4 my-4">
                      Secure SMS number: {this.state.conversationPhone}
                    </div>
                  ) : (
                    <div className="text-center py-4 my-4">
                      <CircularProgress />
                    </div>
                  )}
                </Typography>
              </Typography>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden lgUp>
          <div className="fixed-bottom mb-2">
            <Typography variant="body1">
              <Link to={`/${this.state.slug}/more-info`} className="text-white">
                How we protect your privacy
              </Link>
            </Typography>
          </div>
        </Hidden>
        <Hidden mdDown>
          <div className="position-fixed fixed-bottom pb-4 mb-4">
            <Typography variant="body1">
              <Link to={`/${this.state.slug}/more-info`} className="text-white">
                How we protect your privacy
              </Link>
            </Typography>
          </div>
        </Hidden>
      </div>
    );
  }
}
