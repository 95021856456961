import React, { useState } from 'react';

//mui data table
import CustomMuiDataTable from './StyledMuiDataTable';

//components
import NavBreadcrumbs from './NavBreadcrumbs';
import DisplayChip from './DisplayChip';

//Mui
import TableContainer from '@material-ui/core/TableContainer';
import Hidden from '@material-ui/core/Hidden';
import Switch from '@material-ui/core/Switch';

//Helpers
import HelperTxtByType from './HelperTxtByType';
import moment from 'moment';

//styles
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';

const muiStyles = theme => ({
  chipContainer: {
    paddingBottom: 100,
  },
});

const DataViewTable = ({ conversations, classes }) => {
  const isMobileSize = useMediaQuery('(max-width: 768px)');
  const tableData =
    conversations.length > 0 &&
    conversations.map(c => {
      const obj = {
        id: c.id,
        fileNumber: c.fileNumber,
        serverName: HelperTxtByType('title', c.knockerName),
        serverPhone: HelperTxtByType('phone', c.knockerPhone),
        serveeNumber: HelperTxtByType('phone', c.userPhone),
        status: c.active ? 'Active' : 'Inactive',
        messages: c.messages,
        started: moment(c.createdAt).format('M/DD/YY kk:mm:ss'),
        last: moment(c.updatedAt).format('M/DD/YY kk:mm:ss'),
      };
      return Object.values(obj);
    });
  const [dense, setDense] = useState(false);

  const handleClickData = id => {
    window.location.href = `/adminportal/d/${id}`;
  };

  const toggleDense = () => {
    setDense(!dense);
  };

  //mui datatable section
  const responsiveOptions = [
    'vertical',
    'standard',
    'simple',
    'scroll',
    'scrollMaxHeight',
    'stacked',
  ];
  const filterOptions = ['dropdown', 'checkbox'];

  const options = {
    filter: true,
    selectableRows: false,
    filterType: filterOptions[0],
    onRowClick: rowData => handleClickData(rowData[0]),
    responsive: responsiveOptions[1],
  };

  const columns = [
    {
      name: 'ID',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'File Number',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'Server Name',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: isMobileSize && '180px' } };
        },
      },
    },
    {
      name: 'Server Phone',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: isMobileSize && '180px' } };
        },
      },
    },
    {
      name: 'Consumer Phone',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: isMobileSize && '180px' } };
        },
      },
    },
    {
      name: 'Status',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'Messages',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'Started',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: isMobileSize && '180px' } };
        },
      },
    },
    {
      name: 'Last',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: isMobileSize && '180px' } };
        },
      },
    },
  ];

  return (
    <div className="data data-container root w-100">
      {/* Header */}
      <div className="data-header d-flex flex-row align-items-center justify-content-start">
        <NavBreadcrumbs
          currentStep={`list`}
          previousSteps={['conversations']}
          loaded={conversations.length > 0}
          error={false}
        />
      </div>

      {/* Results */}
      <div className="data-container data-container-list data-container-list-summary w-100 py-1 py-xl-3 py-lg-2 py-md-2 py-sm-1">
        <TableContainer>
          {conversations.length > 0 && (
            <CustomMuiDataTable
              data={tableData}
              title={'Conversations'}
              columns={columns}
              options={options}
            />
          )}
        </TableContainer>
      </div>

      {/* Chips */}
      <div className={classes.chipContainer}>
        <div className="data-footer w-100 d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center justify-content-start">
            {/* @todo: Move to component */}
            <Hidden xlDown>
              <Switch checked={dense} onChange={toggleDense} name="dense" />
            </Hidden>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end">
            <DisplayChip
              labelType={`text`}
              labelFormat={`static`}
              labelVal={`Conversations`}
              displayType={`avatar`}
              displayVal={conversations.length}
              color={`dark`}
              clickable={false}
              loaded={conversations.length > 0}
              error={false}
            />
            {/* *** View All Message Details *** */}
            <DisplayChip
              labelType={`text`}
              labelFormat={`static`}
              labelVal={`All Messages`}
              displayType={`icon`}
              displayVal={`message`}
              color={`dark`}
              clickable={true}
              loaded={conversations.length > 0}
              error={false}
              onClick={() => {
                window.location.href = `/adminportal/messages`;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(muiStyles)(DataViewTable);
