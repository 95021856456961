import React, { useState } from 'react';
// Utils
import HelperTxtByType from './HelperTxtByType';
// Components
import NavBreadcrumbs from './NavBreadcrumbs';
import DisplayChip from './DisplayChip';
import DisplayImage from './DisplayImage';
import DisplayDialog from './DisplayDialog';
// Mui
import Hidden from '@material-ui/core/Hidden';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelperIconByType from './HelperIconByType';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ConfirmEndConversation from './ConfirmEndConversation';
import ImageIcon from '@material-ui/icons/Image';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//mui data table
import CustomMuiDataTable from './StyledMuiDataTable';
//Helpers
import moment from 'moment';

const muiStyles = theme => ({
  recordingLink: {
    cursor: 'pointer',
  },
  chipContainer: {
    paddingBottom: 100,
  },
});

const DataViewListTable = ({ messages, conversation, context, getConversationData, classes }) => {
  const isMobileSize = useMediaQuery('(max-width: 768px)');
  const [exception, setException] = useState(false);
  const [dense, setDense] = useState(false);
  const [loaded, setLoaded] = useState(false);
  // const [active, setActive] = useState('');
  // const [newActive, setNewActive] = useState(false);
  const [chosenRecording, setChosenRecording] = useState('');
  const [selectedMedia, setSelectedMedia] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [endModalOpen, setEndModalOpen] = useState(false);
  const [imgModalOpen, setImgModalOpen] = useState(false);

  const toggleDense = () => {
    setDense(!dense);
  };

  const updateActive = () => {
    setLoaded(true);
    if (conversation.id) {
      fetch(
        `${context}/api/conversation/kill?conversationId=${conversation.id}&authorization=${localStorage.whoknocked_token}`
      )
        .then(r => r.json())
        .catch(e => {
          setException(e);
        })
        .finally(() => {
          getConversationData();
          setLoaded(false);
        });
    }
  };

  // useEffect(() => {
  //   if (conversation) {
  //     setActive(conversation.active);
  //     setNewActive('tbd');
  //   }
  // }, [conversation]);

  //mui datatable section
  const tableData =
    messages.length > 0 &&
    messages.map(m => {
      const obj = {
        sender: m.originId === 1 ? 'Consumer' : m.originId === 2 ? 'Knocker' : 'System',
        type: 'Text',
        timeStamp: moment(m.createdAt).format('M/DD/YY kk:mm:ss'),
        message: m.message,
        recording:
          m.url &&
          (m.twilioMeta.RecordingUrl ? (
            <Grid
              container
              alignItems="center"
              onClick={() => {
                setModalOpen(true);
                setChosenRecording(m.url);
              }}
            >
              <IconButton
                color="secondary"
                size="small"
                aria-label="open record"
                className={classes.recordingLink}
              >
                {HelperIconByType(`recording`, false, 'small')}
              </IconButton>
              <Typography className={classes.recordingLink}>Listen to Recording</Typography>
            </Grid>
          ) : m.twilioMeta.Media ? (
            <Grid
              container
              alignItems="center"
              onClick={() => {
                setSelectedMedia(m.url);
                setImgModalOpen(true);
              }}
            >
              <IconButton
                color="secondary"
                size="small"
                aria-label="open media image"
                className={classes.recordingLink}
              >
                <ImageIcon />
              </IconButton>
              <Typography className={classes.recordingLink}>See Image</Typography>
            </Grid>
          ) : null),
      };
      return Object.values(obj);
    });

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: false,
    customToolbar: () => (
      <Tooltip title={'End Conversation'}>
        <IconButton
          disabled={loaded || !conversation.active}
          onClick={() => {
            setEndModalOpen(true);
          }}
        >
          {loaded ? <CircularProgress size={20} /> : <CloseIcon />}
        </IconButton>
      </Tooltip>
    ),
  };

  const columns = [
    {
      name: 'sender',
      label: 'Sender',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'timestamp',
      label: 'Timestamp',
      options: {
        filter: true,
        setCellProps: () => ({ style: { minWidth: '180px' } }),
        filterType: 'dropdown',
        responsive: 'standard',
      },
    },
    {
      name: 'message',
      label: 'Message',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: isMobileSize && '700px' } };
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
        filter: false,
        sort: false,
      },
    },
  ];

  return (
    <div className="data-container root w-100">
      <div className="data-container root w-100">
        <div className="data-header d-flex flex-row align-items-center justify-content-start">
          <NavBreadcrumbs
            currentStep={`${conversation.knockerName} + ${HelperTxtByType(
              'phone',
              conversation.userPhone,
              false
            )}`}
            previousSteps={['conversations', conversation.id, 'messages']}
            loaded={messages.length > 0}
            error={exception}
          />
        </div>

        {/* Results */}
        <div className="data-container data-container-list data-container-list-detailed w-100 py-0 py-xl-3 py-lg-2 py-md-2 py-sm-1">
          <TableContainer>
            {messages.length > 0 && (
              <CustomMuiDataTable
                data={tableData}
                title={'Conversation Details'}
                columns={columns}
                options={options}
              />
            )}
          </TableContainer>
        </div>

        {/* Chips */}
        <div className={classes.chipContainer}>
          <div className="data-footer w-100 d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center justify-content-start">
              {/* @todo: Move to component */}
              <Hidden xlDown>
                <Switch checked={dense} onChange={toggleDense} name="dense" />
              </Hidden>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-end">
              <Hidden smDown>
                {/* *** Start Date *** */}
                <DisplayChip
                  labelType={`text`}
                  labelFormat={`date`}
                  labelVal={conversation.createdAt}
                  displayType={`icon`}
                  displayVal={`start`}
                  displayParams={{ notime: true, inline: false }}
                  color={`dark`}
                  clickable={false}
                  tooltip={false}
                  loaded={messages.length > 0}
                  error={exception}
                />

                {/* *** Last Date *** */}
                <DisplayChip
                  labelType={`text`}
                  labelFormat={`date`}
                  labelVal={conversation.updatedAt}
                  displayType={`icon`}
                  displayVal={`last`}
                  displayParams={{ notime: true, inline: false }}
                  color={`dark`}
                  clickable={false}
                  tooltip={false}
                  loaded={messages.length}
                  error={exception}
                />
              </Hidden>

              {/* *** Status *** */}
              <DisplayChip
                labelType={`text`}
                labelFormat={`status`}
                labelVal={conversation.active}
                displayType={`icon`}
                displayVal={conversation.active ? `active` : 'inactive'}
                displayParams={{ notime: true, inline: false }}
                color={`dark`}
                clickable={false}
                tooltip={false}
                loaded={messages.length > 0}
                error={exception}
              />

              {/* *** File *** */}
              <DisplayChip
                labelType={`text`}
                labelFormat={`present`}
                labelVal={conversation.fileId ? 'On File' : 'null'}
                displayType={`icon`}
                displayVal={`file`}
                displayParams={{ fileId: conversation.fileId }}
                color={`dark`}
                clickable={false}
                tooltip={false}
                loaded={messages.length > 0}
                error={exception}
              />

              {/* *** Message Count *** */}
              <DisplayChip
                labelType={`text`}
                labelFormat={`static`}
                labelVal={`Messages`}
                displayType={`avatar`}
                displayVal={messages.length}
                color={`dark`}
                clickable={false}
                loaded={messages.length > 0}
                error={exception}
              />

              <div
              // className={`order-${this.state.orderChannel} d-flex flex-row align-items-center justify-content-center data-card-avatar-container mt-auto`}
              >
                <DisplayDialog
                  open={modalOpen}
                  RecordingUrl={chosenRecording}
                  title={`Call Recording`}
                  updateActive={() => setModalOpen(false)}
                />
              </div>
              <div>
                <ConfirmEndConversation
                  open={endModalOpen}
                  title={`Are you sure?`}
                  handleClose={() => {
                    setEndModalOpen(false);
                  }}
                  handleConfirm={updateActive}
                />
              </div>
              <div>
                <DisplayImage
                  open={imgModalOpen}
                  handleClose={() => {
                    setImgModalOpen(false);
                    setSelectedMedia('');
                  }}
                  imgSrc={selectedMedia}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(muiStyles)(DataViewListTable);
