import React from "react";

// Dispatchers

// Utils

// Components

// Mui

/*
   @todo:  Roll out globally
 */

export default class UtilErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    /* *** @todo: Tie into our global logging *** */
    //logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="utils-errors d-flex flex-row align-items-center justify-content-center">
          <div>
            {" "}
            Friendly error message <div>{this.props.error}</div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

/* *** @todo: Add debug mode flag and show details if true *** */
/*

    <div className={`components w-100 h-100 p5 m5`}>
        <div className="w-100 h-100 d-flex flex-row align-items-center justify-content-center error-have-required bg-white text-danger">
          <div className="w-75">
            <div className="py-2">
              <b>Error Debugger</b>
            </div>
            <div className="py-2">
              <b>{this.props.error}</b>
            </div>
          </div>
        </div>
      </div>


        <div className="w-100">
          <div>
            <div className="">conversation:</div>
            {JSON.stringify(this.state.conversation)}
          </div>
          <div>
            <div className="">messages:</div>
            {JSON.stringify(this.state.messages)}
          </div>
        </div>


<div className="sticky-top d-flex flex-row align-items-center justify-content-center">
          <div>
            <span className="mr-4">cId: {this.state.conversation.id}</span>
            <span className="mr-4">Active (db): {this.state.conversation.active && this.state.conversation.active.toString()}</span>
            <span className="mr-4">Active (s): {this.state.active && this.state.active.toString()}</span>
            <span className="mr-4">NewActive (s): {this.state.newActive && this.state.newActive.toString()}</span>
            <span className="mr-4">loaded: {this.state.loaded}</span>
            <span className="mr-4">error: {this.state.error}</span>
          </div>
          <div className="my-2">
            <span className="mr-4">filter: {this.state.filter}</span>
            <span className="mr-4">filterValue: {this.state.filterValue}</span>
          </div>
          <div>
            <span className="mr-4">dense: {this.state.dense}</span>
            <span className="mr-4">view: {this.state.view}</span>
            <span className="mr-4">sort: {this.state.sort}</span>
          </div>
        </div>
 */
