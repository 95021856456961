import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Logo from '../styles/assets/img/logo.png';
import UtilErrorBoundary from './WrErrorBoundary';
import Typography from '@material-ui/core/Typography';
import ModalWhatIs from '../content/ModalWhatIs';
import ModalMission from '../content/ModalMission';
import ModalFaq from '../content/ModalFaq';
import ModalTerms from '../content/ModalTerms';
import ModalPrivacy from '../content/ModalPrivacy';
//styles
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const muiStyles = () => ({
  bottomBar: {
    marginTop: 50,
  },
});

const WrViewContainer = ({ children, classes }) => {
  const isMobileSize = useMediaQuery('(max-width: 768px)');
  const [open, setOpen] = useState(false);
  const isAdmin = window.location.pathname.includes('/adminportal');
  const isDebugging = window.location.pathname.includes('/debugging');

  const handleChange = modal => {
    if (modal) {
      setOpen(modal);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="containerBg">
      {!isAdmin && !isDebugging && (
        <AppBar position="static">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <Link to="/">
              <img
                src={Logo}
                alt="WhoKnocked Logo"
                className="logo p-1 p-xl-2 p-lg-2 p-md-2 p-sm-1"
              />
            </Link>
          </div>
        </AppBar>
      )}

      {/* *** Body *** */}
      <Container
        maxWidth={window.location.pathname === '/adminportal/messages' ? false : 'lg'}
        className="my-1 my-xl-4 my-lg-3 my-md-2 my-sm-1"
      >
        {/* *** Footer *** */}
        <div className="d-flex flex-column justify-content-around align-items-stretch">
          {children}
        </div>

        <UtilErrorBoundary>
          {!isAdmin && !isDebugging && (
            <div className="d-flex flex-row align-items-center justify-content-center">
              <Typography variant="body1">
                {isMobileSize ? (
                  <Grid container justify="space-between" className={classes.bottomBar}>
                    <Grid item>
                      <span
                        onClick={() => handleChange('WhatIs')}
                        className="text-nowrap clickable"
                      >
                        What is WhoKnocked?
                      </span>
                    </Grid>
                    <Grid item>
                      <span
                        onClick={() => handleChange('Mission')}
                        className="text-nowrap clickable"
                      >
                        Mission Statement
                      </span>
                    </Grid>
                    <Grid item>
                      <span onClick={() => handleChange('Faq')} className="text-nowrap clickable">
                        FAQ
                      </span>
                    </Grid>
                    <Grid item>
                      <span onClick={() => handleChange('Terms')} className="text-nowrap clickable">
                        Terms and Conditions
                      </span>
                    </Grid>
                    <Grid item>
                      <span
                        onClick={() => handleChange('Privacy')}
                        className="text-nowrap clickable"
                      >
                        Privacy Policy
                      </span>
                    </Grid>
                  </Grid>
                ) : (
                  <div className={classes.bottomBar}>
                    <span onClick={() => handleChange('WhatIs')} className="text-nowrap clickable">
                      What is WhoKnocked?
                    </span>
                    <span className="mx-1">•</span>
                    <span onClick={() => handleChange('Mission')} className="text-nowrap clickable">
                      Mission Statement
                    </span>
                    <span className="mx-1">•</span>
                    <span onClick={() => handleChange('Faq')} className="text-nowrap clickable">
                      FAQ
                    </span>
                    <span className="mx-1">•</span>
                    <span onClick={() => handleChange('Terms')} className="text-nowrap clickable">
                      Terms and Conditions
                    </span>
                    <span className="mx-1">•</span>
                    <span onClick={() => handleChange('Privacy')} className="text-nowrap clickable">
                      Privacy Policy
                    </span>
                  </div>
                )}
              </Typography>
              <ModalWhatIs open={open === 'WhatIs'} onClose={handleClose} />
              <ModalMission open={open === 'Mission'} onClose={handleClose} />
              <ModalFaq open={open === 'Faq'} onClose={handleClose} />
              <ModalTerms open={open === 'Terms'} onClose={handleClose} />
              <ModalPrivacy open={open === 'Privacy'} onClose={handleClose} />
            </div>
          )}
        </UtilErrorBoundary>
      </Container>
    </div>
  );
};

export default withStyles(muiStyles)(WrViewContainer);
