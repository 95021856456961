import React from "react";

// Dispatchers
// Constructor/s
import ConstrLandingNoSlug from "../constructors/ConstrLandingNoSlug";

// Components

export default class ViewLandingNoSlug extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
    };
  }

  render() {
    return <ConstrLandingNoSlug />;
  }
}
