import React from 'react';
import PropTypes from 'prop-types';

// UI components.
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';

export default function ModalFaq(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(true);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="faq" open={open} maxWidth="md" scroll="paper">
      <DialogTitle id="faq-dialog-title">
        <Typography variant="h4" className="py-4">
          <span className="text-dark font-weight-bold">FAQ</span>
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <div className="text-dark">
            <div className="pb-3">
              <span className="font-weight-bold mr-1">
                Does WhoKnocked have my personal information?
              </span>
              No. We have a name and address, which is provided on the legal documents.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">
                What kind of questions should I expect from a WhoKnocked Process Server?
              </span>
              We cannot regulate or censure what kinds of questions process servers can ask you.
              Process Servers are expected to be courteous, professional, and conduct themselves as
              ambassadors to the legal profession (not legal practitioners). The Process Server may
              ask how long you have resided at the address and if you have any affiliation with the
              named party(ies) being served.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">Why did you come to my address?</span>
              Process Servers are required to attempt service at the address provided on the legal
              documents. Sometimes that address is accurate, sometimes it isn’t. Process Servers
              must establish diligent effort to contact the named party(ies) at the address provided
              on the legal documents.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">
                I’d like to help the process server, but I don’t feel comfortable sharing my
                information
              </span>
              No problem, any information you provide will be held as confidential.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">
                How do I know the WhoKnocked Process Server is a “real” Process Server?
              </span>
              Fair enough…most states regulate who can and can not be a Process Server. WhoKnocked
              Process Servers are verified prior to being permitted to use our platform. If you want
              to investigate if a Process Server is legitimate, you would need to verify that with
              the licensing authority in your state.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">Can WhoKnocked help me in court?</span>
              WhoKnocked is not a law firm and is not authorized to practice law. We cannot and will
              not provide legal advice to you.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">Is WhoKnocked an attorney?</span>
              No.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">Is WhoKnocked a law firm?</span>
              No.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">
                Does WhoKnocked work as a debt settlement company?
              </span>
              No.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">Does WhoKnocked cost me anything?</span>
              There is no cost or obligation to participate in the WhoKnocked process.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold">
                How do I learn more about the person knocking on my door?
              </span>
              Simple. Reach out to the process server via our discrete, secure communication
              channel. The Process Server will share what they can to help you understand why they
              are attempting to contact you.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">
                The person needing to be served no longer lives at my address. How do I make the
                knocks stop?
              </span>
              Just let us know. Keep in mind, WhoKnocked cannot guarantee additional attempts to
              contact someone who used to live at your address will stop. WhoKnocked gathers the
              information provided and submits it to the filing party of the case. The party
              initiating the suit ultimately decides what occurs from there.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">
                How can I notify everyone at once the person being served no longer lives at my
                address?
              </span>
              There are some steps you can take to minimize process servers from showing up. If you
              receive mail addressed to someone who no longer lives at your address, you can return
              the letter by following USPS guidelines regarding returning mail to the original
              sender.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">
                I received a WhoKnocked Card…but it is for my neighbor…what do I do?
              </span>
              You are under NO OBLIGATION to do anything with the WhoKnocked Card. You can let us
              know the card was left at the wrong address via our discreet and secure communication.
              We would suggest NOT taking the card to the correct address. The WhoKnocked team will
              NEVER ask you to take the card to the correct address. If you are asked by a Process
              Server utilizing the WhoKnocked platform to do such a thing, please let us know by
              selecting the Intercom Icon (located at the WhoKnocked Home Page). Your report will be
              investigated. Your report will remain confidential.
            </div>

            <div className="pb-3">
              <span className="font-weight-bold mr-1">I would like to partner with WhoKnocked</span>
              Great – You can reach us by selecting the Intercom Icon, located at the WhoKnocked
              Home Page.{' '}
              <Link to={`/`}>
                <span className="text-primary">Or by clicking here</span>
              </Link>
              .
            </div>
          </div>
        </Typography>
        <Divider />
      </DialogContent>

      <DialogActions>
        <div className="p-4">
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ModalFaq.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
