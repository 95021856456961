import React from "react";

// Constructor/s
import ConstrPublicError from "../constructors/ConstrPublicError";

export default class ViewPublicError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
    };
  }

  render() {
    return <ConstrPublicError />;
  }
}
