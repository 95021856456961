import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

export default function LoaderProgressCircle(props) {
  return (
    <div className="fixed-top containerBg d-flex flex-row justify-content-center align-items-center">
      <CircularProgress color="primary" size={50} />
    </div>
  );
}
