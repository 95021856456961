import React from "react";

// Dispatchers
// Constructor
import ConstrProtectedConversationDetails from "../constructors/ConstrProtectedConversationDetails";

// Components

// Responsive

export default class ViewProtectedConversationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
      conversationId: this.props.match.params.id,
    };
  }

  render() {
    return (
      <ConstrProtectedConversationDetails
        conversationId={this.props.match.params.id}
      />
    );
  }
}
