import React from 'react';
import PropTypes from 'prop-types';

// UI components.
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

export default function ModalWhatIs(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(true);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="whatIs" open={open} maxWidth="md" scroll="paper">
      <DialogTitle id="whatIs-dialog-title">
        <Typography variant="h4" className="py-4">
          <span className="text-dark font-weight-bold">What Is WhoKnocked</span>
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <div className="text-dark">
            <div className="pb-3">
              WhoKnocked is a web-based platform where YOU can connect with process servers who may
              be trying to reach you. You and process servers can discreetly and securely
              communicate with one another regarding the matter. Based on the information received,
              you can schedule a time to accept service of process from the process server.
              WhoKnocked will have the ability to connect you directly with the party of the suit,
              which may help you resolve the matter.
            </div>
            <div className="pb-3">
              Process Servers attempt to reach thousands of named parties to perform service of
              process every day across the world. From time to time, the information (such as the
              address of the named party) may have changed. WhoKnocked allows the person finding the
              WhoKnocked card to quickly and easily relay that change, without actually “facing” the
              process server. This minimizes uninvolved parties from being inconvenienced by a
              process server continuing to “knock” on the improper door.
            </div>
            <div className="pb-3">
              Assuming the change in information provided can be independently verified, the process
              server will cease continuing to “knock” on your door.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Why is WhoKnocked important?</span>
            </Typography>
            <div className="pb-3">
              From your perspective, WhoKnocked can play a critical role in helping maintain dignity
              and discretion in the service of the legal document process. Being served legal
              documents can be an emotional and possibly embarrassing experience. Having an
              unfamiliar person(s) approach your home and knock on your door can bring about a sense
              of irritation. While WhoKnocked doesn’t eliminate the legal matter at issue, we do
              offer you a basic alternative to the actual service itself. In some cases, allowing
              you to set the date and time for service to take place.
            </div>
            <div className="pb-3">
              We all value the protections and comfort of being safe and secure in our homes. In
              instances (unknown to the process server at the time) where the information provided
              about the named party has changed, WhoKnocked facilitates and helps reduce unwanted,
              unnecessary, and repeated visits to your place of residence. Your
            </div>
            <div className="pb-3">
              Process Servers spend countless hours knocking on doors. Often, process servers are
              driving to and from houses attempting to make contact with consumers. WhoKnocked helps
              reduce the environmental impact of the process server. If information can be obtained
              by the consumer, without additional travel to and from the address in question, then
              our mission is a success. Obviously, this information would have to be independently
              verified, but less the time and effort of returning to an address (and possibly
              avoiding additional disturbance to someone NOT involved in the action).
            </div>
            <div className="pb-3">
              Aside from the environmental considerations, WhoKnocked facilitates communication
              between parties involved in a legal dispute. Opening a communication channel to help
              resolve the issue without judicial involvement generally is a win for all parties.
              Will all disputes be resolved via WhoKnocked? Probably not. If small percentages are
              closed with the disputing parties finding mutually beneficial terms, then that
              calculates to time and effort saved by everyone involved (for example, taking time off
              of from work to go to court).
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Mission Statement</span>
            </Typography>
            <div className="pb-3">
              WhoKnocked aims to bring security and transparency to the service of process (process
              serving). Through technology, WhoKnocked offers YOU the ability to have a say on when
              and where you are served. This makes getting served safer and eliminates the chance of
              being served in an undesirable setting.
            </div>
          </div>
        </Typography>
        <Divider />
      </DialogContent>

      <DialogActions>
        <div className="p-4">
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ModalWhatIs.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
