import React from "react";

// Constructor/s
import ConstrPublicConnected from "../constructors/ConstrPublicConnected";

export default class ViewPublicConnected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      slug: this.props.match.params.slug,
    };
  }

  render() {
    return <ConstrPublicConnected slug={this.state.slug} />;
  }
}
