import React from "react";

// Components
import ButtonBack from "../components/NavBackBtn";
import ControlChat from "../components/ControlChat";

// Mui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import NavHeaderTitle from "../components/NavHeaderTitle";

export default function ConstrPublicError() {
  return (
    <div className="w-100">
      <NavHeaderTitle title={"Oops!"} />
      <Grid
        container
        direction="row"
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="text-center py-4"
        >
          <Typography variant="h6">
            <div>Something went wrong. We apologize for the inconvenience.</div>
            <Hidden xsDown>
              <div className="pt-2 lead">
                <i>
                  Please click one of the buttons below to go back or to chat
                  with support .
                </i>
              </div>
            </Hidden>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={5} xl={4}>
          <ButtonBack />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={5} xl={4}>
          <ControlChat />
        </Grid>
      </Grid>
    </div>
  );
}
