import React from "react";

// Constructor
import ConstrProtectedConversationList from "../constructors/ConstrProtectedConversationList";

export default class ViewProtectedConversationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
    };
  }

  render() {
    return <ConstrProtectedConversationList />;
  }
}
