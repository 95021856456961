import React from 'react';

// Constructor/s
import ConstrPublicMoreInfo from '../constructors/ConstrPublicMoreInfo';

export default class ViewPublicMoreInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      slug: this.props.match.params.slug,
    };
  }

  render() {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_KEY,
    });
    return <ConstrPublicMoreInfo slug={this.state.slug} />;
  }
}
