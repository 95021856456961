/*
Temporary view to handle situations where consumers enter a bad Process Server slug or no Process Server slug.
 */
import React, { useEffect, useState } from 'react';

//mui components
import Button from '@material-ui/core/Button';

// UI components.
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ControlChat from '../components/ControlChat';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

// Responsive.
import NavHeaderTitle from '../components/NavHeaderTitle';

const muiStyles = theme => ({
  desc: {
    '@media (max-width: 768px)': {
      fontSize: 17,
    },
  },
  whoKnocked: {
    fontSize: 18,
    '@media (max-width: 768px)': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  textField: {
    height: 40,
    fontWeight: 400,
    fontSize: 14,
  },
});

const ConstrPublicBadSlug = ({ classes }) => {
  const [slug, setSlug] = useState(null);

  const handleSlugInput = event => {
    setSlug(event.target.value);
  };

  const handleGo = () => {
    if (slug) {
      window.location.href = `/${slug}`;
    }
  };

  const logKey = e => {
    if (e.code === 'Enter' && slug) {
      window.location.href = `/${slug}`;
    }
  };

  document.addEventListener('keypress', logKey);

  return (
    <div className="containerView">
      <NavHeaderTitle title={'Oops! Something went wrong.'} />
      <Grid container direction="column" spacing={3} justify="flex-start" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center pt-4">
          <Typography variant="h6" gutterBottom className={classes.desc}>
            <div>Please try again with a valid Process Server's ID included in this format:</div>
            <div className="pt-2 lead">
              <i>
                WhoKnocked.com/<b>John.S</b>
              </i>
            </div>
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={10} md={6} lg={5} xl={5} alignItems="center" spacing={1}>
          <Grid item lg={3} md={3} xs={5} className={classes.whoKnocked}>
            WhoKnocked.com/
          </Grid>
          <Grid item lg={1} md={1} xs={1} />
          <Grid item lg={5} md={5} xs={6}>
            {/* <CustomSelectAuto
              sourceArray={knockers}
              valueKey={'slug'}
              nameKey={'slug'}
              value={slug}
              onChange={value => setSlug(value)}
              placeholder="John.S"
              isLoading={loading}
              disabled={selectDisabled}
            /> */}
            <TextField
              variant="outlined"
              placeholder="John.S"
              className={classes.textField}
              size="small"
              onChange={handleSlugInput}
            />
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={handleGo}
              disabled={!slug}
              disableElevation
              fullWidth
            >
              Go
            </Button>
          </Grid>
          <Grid item xs={12} className="text-center pt-3 mt-3">
            <ControlChat />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(muiStyles)(ConstrPublicBadSlug);
