import React from "react";
import { Link } from "react-router-dom";

// Utility
import getPrettyPhone from "../utils/getPrettyPhone";

// Mui
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function DisplayBlockSuccess(props) {
  const slug = props.slug;

  let conversationPhone = props.conversationPhone;

  if (conversationPhone) {
    conversationPhone = getPrettyPhone(props.conversationPhone);
  }

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      justify="flex-start"
      alignItems="center"
    >
      <Hidden lgUp>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="pt-1 mt-1"
        >
          <Typography variant="h4">You are now connected!</Typography>
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="pt-5 mt-5"
        >
          <Typography variant="h4">You are now connected!</Typography>
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="text-center pt-4 mt-4"
      >
        <Hidden lgUp>
          <Typography variant="body1" gutterBottom>
            <div>
              You will receive a text message connecting you and your process
              server.
            </div>
            <div>It can take up to five minutes.</div>
            <div>
              If you do not receive a text message, please{" "}
              <Link to={`/${slug}/more-info`} className="text-white">
                click here
              </Link>
              .
            </div>
            <Typography variant="subtitle1" gutterBottom>
              <div className="text-center py-4 my-4">
                Secure SMS number: {conversationPhone}
              </div>
            </Typography>
          </Typography>
        </Hidden>
        <Hidden mdDown>
          <Typography variant="h6" gutterBottom>
            <div>
              You will receive a text message connecting you and your process
              server.
            </div>
            <div>It can take up to five minutes.</div>
            <div>
              If you do not receive a text message, please{" "}
              <Link to={`/${slug}/more-info`} className="text-white">
                click here
              </Link>
              .
            </div>
            <Typography variant="subtitle1" gutterBottom>
              <div className="text-center py-4 my-4">
                Secure SMS number: {conversationPhone}
              </div>
            </Typography>
          </Typography>
        </Hidden>
      </Grid>
    </Grid>
  );
}
