import React from 'react';

// Constructor
import ConstrProtectedMessageList from '../constructors/ConstrProtectedMessageList';

export default class ViewProtectedMessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
    };
  }

  render() {
    return <ConstrProtectedMessageList />;
  }
}
