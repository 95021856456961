import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import UtilErrorBoundary from '../wrappers/WrErrorBoundary';
import LoaderSkeleton from './LoaderSkeleton';

export default class NavBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: props.loaded,
      error: props.error,
    };
  }

  componentDidMount() {}

  //currentStep={`${this.state.conversation.name} w/ ${this.state.conversation.phone }`}
  //previousSteps={[ 'conversations', 'messages' ]}

  render() {
    return (
      <LoaderSkeleton loaded={this.props.loaded}>
        <UtilErrorBoundary>
          <div className="d-flex flex-row justify-content-start align-items-center pt-1 components">
            <Breadcrumbs aria-label="breadcrumb" className="data-breadcrumbs">
              {this.props.previousSteps < 1 ? (
                <span> </span>
              ) : (
                this.props.previousSteps.map((step, index) => (
                  <Link
                    color="inherit"
                    // href={`/adminportal/list`}
                    // to={`/adminportal/list`}
                    onClick={() => {
                      window.location.href = '/adminportal/list';
                    }}
                  >
                    <span className="text-capitalize">{step}</span>
                  </Link>
                ))
              )}

              <span className="text-capitalize">{this.props.currentStep}</span>
            </Breadcrumbs>
          </div>
        </UtilErrorBoundary>
      </LoaderSkeleton>
    );
  }
}
