/*
View for consumers who enter no Process Server slug.
 */
import React, { useEffect, useState } from 'react';

// UI components.
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ControlChat from '../components/ControlChat';
import NavHeaderTitle from '../components/NavHeaderTitle';
import CustomSelectAuto from '../components/CustomSelectAuto';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

// Responsive.
//import Hidden from "@material-ui/core/Hidden";

const muiStyles = theme => ({
  desc: {
    '@media (max-width: 768px)': {
      fontSize: 17,
    },
  },
  whoKnocked: {
    fontSize: 18,
    '@media (max-width: 768px)': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  textField: {
    height: 40,
    fontWeight: 400,
    fontSize: 14,
  },
});

const ConsumerNoSlug = ({ classes }) => {
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_KEY, // "fyq3wodw", // test API key for comparison
  });

  // const [knockers, setKnockers] = useState([]);
  const [slug, setSlug] = useState(null);

  // useEffect(() => {
  //   getKnockerSlugsList()
  //     .then(slugs => {
  //       setKnockers(slugs.data);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }, []);

  // const getKnockerSlugsList = async () => {
  //   try {
  //     const result = await fetch(
  //       `/api/knockers/get?authorization=${localStorage.whoknocked_general_token}`
  //     );
  //     return await result.json();
  //   } catch (err) {
  //     console.log(err);
  //     // we need some component to catch the thrown exceptions!!!
  //   }
  // };

  const handleGo = () => {
    if (slug) {
      window.location.href = `/${slug}`;
    }
  };

  const handleSlugInput = event => {
    setSlug(event.target.value);
  };

  const logKey = e => {
    if (e.code === 'Enter' && slug) {
      window.location.href = `/${slug}`;
    }
  };

  document.addEventListener('keypress', logKey);

  return (
    <div className="containerView">
      <NavHeaderTitle title={'Received WhoKnocked Card?'} />
      <Grid container direction="column" spacing={3} justify="flex-start" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={11} xl={10} className="text-center pt-2 mt-2">
          <Typography variant="h6" className={classes.desc}>
            <div>
              One of our Process Servers left you a card. Please provide us the Process Server's ID
              in this format:{' '}
              <span className="font-weight-bold font-italic">WhoKnocked.com/John.S</span>
            </div>
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={10} md={6} lg={5} xl={5} alignItems="center" spacing={1}>
          <Grid item lg={3} md={3} xs={5} className={classes.whoKnocked}>
            WhoKnocked.com/
          </Grid>
          <Grid item lg={1} md={1} xs={1} />
          <Grid item lg={5} md={5} xs={6}>
            {/* <CustomSelectAuto
              sourceArray={knockers}
              valueKey={'slug'}
              nameKey={'slug'}
              value={slug}
              onChange={value => setSlug(value)}
              placeholder="John.S"
              isLoading={loading}
              disabled={selectDisabled}
            /> */}
            <TextField
              variant="outlined"
              placeholder="John.S"
              className={classes.textField}
              size="small"
              onChange={handleSlugInput}
            />
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={handleGo}
              disabled={!slug}
              disableElevation
              fullWidth
            >
              Go
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <NavHeaderTitle title={'Mission Statement'} />
      <Grid container direction="column" spacing={3} justify="flex-start" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={11} xl={10} className="text-center pt-2 mt-2">
          <Typography variant="h6" className={classes.desc}>
            <div>
              WhoKnocked aims to bring security and transparency to the service of process (process
              serving). Through technology, WhoKnocked offers{' '}
              <span className="font-weight-bold font-italic">YOU</span> the ability to have a say on
              when and where you are served. This makes getting served safer and eliminates the
              chance of being served in an undesirable setting.
            </div>
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={10} md={6} lg={5} xl={5} alignItems="center" spacing={2}>
          <Grid item xs={12} className="text-center pt-3 mt-3">
            <ControlChat />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(muiStyles)(ConsumerNoSlug);
