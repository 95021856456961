import React, { useState, useEffect } from 'react';

// Components
import LoaderProgressCircle from './LoaderProgressCircle';

// Utilities
import UtilErrorBoundary from '../wrappers/WrErrorBoundary';
import formatNameFromSlug from '../utils/formatNameFromSlug';

// Mui
import Typography from '@material-ui/core/Typography';

const DisplayCardKnockerHorizontal = props => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.slug) {
      setLoaded(true);
    }
  }, [props.slug]);

  return (
    <UtilErrorBoundary>
      <div className="d-flex flex-row justify-content-center align-items-center">
        {loaded ? (
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={props.avatar}
                className="avatarKnocker border border-white rounded-circle"
                alt={props.name}
              />
              <Typography variant="h6">
                <span className="text-capitalize">{formatNameFromSlug(props.slug)}</span>
              </Typography>
            </div>

            <div className="d-flex flex-row justify-content-start align-items-center ml-5 pb-4">
              <div className="talkBubble text-left">
                <Typography variant="body2" color="inherit">
                  Hi! I would like to schedule a time to deliver your documents.
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <LoaderProgressCircle />
        )}
      </div>
    </UtilErrorBoundary>
  );
};

export default DisplayCardKnockerHorizontal;
