import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ContextEndpointProvider } from './front-end/context/ContextEndpoint';

import App from './App';
import theme from './front-end/styles/theme';
import './front-end/styles/assets/styles.v1.css';
import './front-end/styles/assets/styles.v2.css';
import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
  <ContextEndpointProvider value={{ url: setEndpointUrl() }}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </ContextEndpointProvider>,
  document.getElementById('root')
);

function setEndpointUrl() {
  const currentEnv = process.env.REACT_APP_ENV;

  switch (currentEnv) {
    case 'local':
      return 'http://localhost:3001';
    case 'development':
      try {
        if (window.location.protocol === 'https:') {
          return 'https://dev.whoknocked.com';
        } else {
          return 'http://dev.whoknocked.com';
        }
      } catch {
        return 'http://dev.whoknocked.com';
      }
    case 'production':
      try {
        if (window.location.protocol === 'https:') {
          return 'https://whoknocked.com';
        } else {
          return 'http://whoknocked.com';
        }
      } catch {
        return 'http://dev.whoknocked.com';
      }
    default:
      return 'http://localhost:3001';
  }
}
