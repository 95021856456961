import React from 'react';

// Constructors
import ConstrLandingSlug from '../constructors/ConstrLandingSlug';

// Components
// Utils
import LoaderProgressCircle from '../components/LoaderProgressCircle';

export default class ViewLandingSlug extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
    };
  }

  render() {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_KEY,
    });
    return (
      <div className="w-100 h-100">
        {this.state.loaded ? (
          <ConstrLandingSlug slug={this.props.match.params.slug} />
        ) : (
          <LoaderProgressCircle />
        )}
      </div>
    );
  }
}
