import React from 'react';

// Context
import ContextEndpoint from '../context/ContextEndpoint';

// Components
import DisplayCardKnockerHorizontal from '../components/DisplayCardKnockerHorizontal';
import NavHeaderSubTitle from '../components/NavHeaderSubTitle';
import DisplayBlockPrivacy from '../components/DisplayBlockPrivacy';
import BtnStaticNoPhone from '../components/BtnStaticNoPhone';
import BtnStaticHowWeProtect from '../components/BtnStaticHowWeProtect';
import getPrettyPhone from '../utils/getPrettyPhone';
import DisplayBlockSuccess from '../components/DisplayBlockSuccess';
import LoaderProgressCircle from '../components/LoaderProgressCircle';
import NavHeaderTitle from '../components/NavHeaderTitle';

// Mui
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import Button from '@material-ui/core/Button';

export default class ConstrLandingSlug extends React.Component {
  static contextType = ContextEndpoint;

  constructor(props) {
    super(props);
    this.state = {
      slug: '',
      paramSlug: this.props.slug,
      name: '',
      avatar: '',
      id: '',
      fileNumber: '',
      consumerPhoneNum: '',
      consumerPhoneNumStripped: '',
      buttonDisabled: true,
      conversationPhone: '',
      loaded: false,
      error: false,
      endpointUrl: '',
    };
    this.handleSaveForm = this.handleSaveForm.bind(this);
    this.handleFileNumber = this.handleFileNumber.bind(this);
    this.handleConsumerPhoneNum = this.handleConsumerPhoneNum.bind(this);
    this.handleButtonState = this.handleButtonState.bind(this);
  }

  // Handle saving form values to the database.
  handleSaveForm = event => {
    this.setState({ loaded: false });

    fetch(
      `${this.context.url}/api/conversation/set?knockerId=${this.state.id}&consumerPhone=${this.state.consumerPhoneNumStripped}&fileNumber=${this.state.fileNumber}&authorization=${localStorage.whoknocked_general_token}`
    )
      .then(r => r.json())
      .then(d => {
        this.setState({ conversationPhone: d.data.conversationPhone });
      })
      .catch(e => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loaded: true });
      });
  };

  // Handle file number field validation and cleanup (if needed).
  handleFileNumber = event => {
    let rawFileNumber = event.target.value;
    rawFileNumber = rawFileNumber.trim();
    rawFileNumber = rawFileNumber.substring(0, 7);
    rawFileNumber = rawFileNumber.replace(/\D/g, '');

    this.setState({ fileNumber: rawFileNumber });
  };

  // Handle phone number field validation and cleanup.
  handleConsumerPhoneNum = event => {
    // Remove all but numeric values from the phone number.
    let rawConsumerPhoneNum = event.target.value;
    let cleanConsumerPhoneNumStripped = rawConsumerPhoneNum.replace(/\D/g, '');
    cleanConsumerPhoneNumStripped = cleanConsumerPhoneNumStripped.substring(0, 10);
    this.setState({ consumerPhoneNumStripped: cleanConsumerPhoneNumStripped });

    // Format phone number for display.
    let cleanConsumerPhoneNum = cleanConsumerPhoneNumStripped;
    cleanConsumerPhoneNum = getPrettyPhone(cleanConsumerPhoneNum);

    this.setState({ consumerPhoneNum: cleanConsumerPhoneNum });

    // Enable the form submit button once we have a valid phone number.
    this.handleButtonState(cleanConsumerPhoneNumStripped);
  };

  // Handle validating that form field values pass validation before we enable the submit button.
  handleButtonState = s => {
    if (s.length === 10) {
      this.setState({ buttonDisabled: false });
    } else {
      this.setState({ buttonDisabled: true });
    }
  };

  // Handle fetching knocker details.
  getKnockerDetails = s => {
    this.setState({ loaded: false });
    this.setState({ name: '' });
    this.setState({ avatar: '' });
    this.setState({ id: '' });
    this.setState({ slug: '' });

    fetch(
      `${this.context.url}/api/knocker/get?slug=${s}&authorization=${localStorage.whoknocked_general_token}`
    )
      .then(r => r.json())
      .then(d => {
        if (d.status) {
          this.setState({ name: d.data.name });
          this.setState({ avatar: d.data.avatar });
          this.setState({ id: d.data.id });
          this.setState({ slug: d.data.slug });
        } else {
          window.location.href = '/oops';
        }
      })
      .catch(() => {
        window.location.href = '/oops';
      })
      .finally(() => {
        this.setState({ loaded: true });
      });
  };

  componentDidMount() {
    this.getKnockerDetails(this.props.slug);
  }

  componentWillUnmount() {
    this.setState({ name: '' });
    this.setState({ avatar: '' });
    this.setState({ id: '' });
    this.setState({ slug: '' });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.slug !== prevProps.slug) {
      this.getKnockerDetails(this.props.slug);
    }
  }

  render() {
    return (
      <div className="w-100 h-100">
        {this.state.loaded ? (
          <div className="w-100">
            {this.state.conversationPhone ? (
              <DisplayBlockSuccess
                slug={this.state.slug}
                conversationPhone={this.state.conversationPhone}
              />
            ) : (
              <div className="w-100">
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={10} md={8} lg={7} xl={6}>
                    <NavHeaderTitle title={`Connect With A Process Server`} />
                  </Grid>
                  <Grid item xs={12} sm={10} md={8} lg={7} xl={6}>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <DisplayCardKnockerHorizontal
                          slug={this.state.slug}
                          id={this.state.id}
                          name={this.state.name}
                          avatar={this.state.avatar}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <NavHeaderSubTitle
                          subtitle={`We can establish a private, secure text message channel between you and your Process Server`}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="fileNumber"
                          label="File number"
                          variant="filled"
                          size="small"
                          color="secondary"
                          fullWidth
                          onChange={this.handleFileNumber}
                          value={this.state.fileNumber}
                          InputProps={{
                            endAdornment: (
                              <Tooltip
                                title={
                                  <Typography variant="body2" className="p-2">
                                    On the card you received, your Process Server wrote a file
                                    number. Please provide that in this field.
                                  </Typography>
                                }
                                placement="top"
                                arrow
                              >
                                <div className="formAdornmentWrapper">
                                  <HelpOutlinedIcon className="formAdornment" />
                                </div>
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="phoneNumber"
                          label="Your phone number"
                          variant="filled"
                          color="secondary"
                          size="small"
                          fullWidth
                          required
                          onChange={this.handleConsumerPhoneNum}
                          value={this.state.consumerPhoneNum}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          size="large"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          disableElevation
                          disabled={this.state.buttonDisabled}
                          onClick={this.handleSaveForm}
                        >
                          Start Chatting
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={10} md={8} lg={7} xl={6}>
                    <Grid
                      container
                      direction="column"
                      spacing={3}
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <DisplayBlockPrivacy />
                      </Grid>

                      <Grid item xs={12}>
                        <BtnStaticNoPhone slug={this.state.slug} />
                      </Grid>
                      <Grid item xs={12}>
                        <BtnStaticHowWeProtect variant={`text`} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        ) : (
          <LoaderProgressCircle />
        )}
      </div>
    );
  }
}
