import React from 'react';

// Icons
/* *** Roles *** */
/* user */
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
//import PersonIcon from "@material-ui/icons/Person";
/* Process Server */
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
//import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
/* system */
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
//import CloudCircleRoundedIcon from "@material-ui/icons/CloudCircleRounded";
//import CloudRoundedIcon from "@material-ui/icons/CloudRounded";
/* *** Conversation Attributes *** */
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
/* files */
// have
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
// missing
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';

/* *** Message State *** */
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

/* *** Message Channels *** */
/* sms */
//import MailTwoToneIcon from "@material-ui/icons/MailTwoTone";
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
//import LocalPostOfficeRoundedIcon from "@material-ui/icons/LocalPostOfficeRounded";
//import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
/* voice */
//import RingVolumeRoundedIcon from "@material-ui/icons/RingVolumeRounded";
import MicNoneRoundedIcon from '@material-ui/icons/MicNoneRounded';
//import PhoneInTalkRoundedIcon from "@material-ui/icons/PhoneInTalkRounded";
//import DialpadRoundedIcon from "@material-ui/icons/DialpadRounded";
//import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
//import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
/* chat */
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
/* facebook */
import FacebookIcon from '@material-ui/icons/Facebook';
/* whatsapp */
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
/* message */
import MessageIcon from '@material-ui/icons/Message';
import GTranslateIcon from '@material-ui/icons/GTranslate';
/* *** Message Artifacts *** */
/* recording */
import VoicemailOutlinedIcon from '@material-ui/icons/VoicemailOutlined';
/* transcript */
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined';
//import ShortTextOutlinedIcon from '@material-ui/icons/ShortTextOutlined';
//import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
/* *** Message Attributes *** */
/* timestamp */
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

/* *** File Number Attributes *** */
// if originId = 3 in "Files" table then note that our system found it automatically.
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';

export default function HelperIconByType(role, qualifer, size) {
  if (!size) {
    size = 'default';
  }

  // Pick the right icon to use based on role of the object it will appear next to.
  switch (role) {
    // Roles
    case 'user':
      return <AccountCircleRoundedIcon fontSize={size} />;
    case 'knocker':
      return <VerifiedUserRoundedIcon fontSize={size} />;
    case 'system':
      return <FaceRoundedIcon fontSize={size} />;
    // Context
    case 'start':
      return <EventAvailableIcon fontSize={size} />;
    case 'last':
      return <EventBusyIcon fontSize={size} />;
    case 'file':
      try {
        if (qualifer.fileId) {
          return <DescriptionOutlinedIcon fontSize={size} />;
        } else {
          return <FindInPageOutlinedIcon fontSize={size} />;
        }
      } catch {
        return <DescriptionOutlinedIcon fontSize={size} />;
      }

    // Status
    case 'inactive':
      return <NotificationsOffOutlinedIcon fontSize={size} />;
    case `active`:
      return <NotificationsActiveOutlinedIcon fontSize={size} />;
    // Message Channels
    case `sms`:
      return <MailOutlineOutlinedIcon fontSize={size} />;
    case `voice`:
      return <MicNoneRoundedIcon fontSize={size} />;
    case `chat`:
      return <ModeCommentOutlinedIcon fontSize={size} />;
    case `facebook`:
      return <FacebookIcon fontSize={size} />;
    case `whatsapp`:
      return <WhatsAppIcon fontSize={size} />;
    // Message State
    case 'delivered':
      return <VerifiedUserIcon fontSize={size} />;
    // Message Artifacts
    case 'recording':
      return <VoicemailOutlinedIcon fontSize={size} />;
    case 'transcript':
      return <TextFieldsOutlinedIcon fontSize={size} />;
    // Message Attributes
    case 'timestamp':
      return <ScheduleOutlinedIcon fontSize={size} />;
    // File Attributes
    case 'automatic':
      return <BrightnessAutoIcon fontSize={size} />;
    case 'message':
      return <MessageIcon fontSize={size} />;
    case 'transcription':
      return <GTranslateIcon fontSize={size} />;
    default:
      return <HelpOutlineOutlinedIcon fontSize={size} />;
  }
}
