import React from 'react';

// Mui
import Typography from '@material-ui/core/Typography';
import LockIcon from '../styles/assets/img/lock-icon.svg';
import ModalFaq from '../content/ModalFaq';

export default function DisplayBlockPrivacy() {
  const [open, setOpen] = React.useState(false);

  // Handle FAQ modal open / close.
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="w-100 d-flex flex-column align-items-start justify-content-center">
        <div className="w-100 text-center d-flex flex-row align-items-start justify-content-center">
          <div className="pl-2 text-left">
            <img
              src={LockIcon}
              className="icon float-left pr-1 pt-2"
              alt="Your number is never shared"
            />
            <Typography variant="caption">
              <span className="mr-1">
                Your number is never shared with anyone. Our system will mask your number and the
                Process Server's number. You have the right to share as much, or as little as you
                like.
              </span>
              <span onClick={handleClickOpen}>
                <Typography variant="caption" className="text-nowrap pb-1 clickable">
                  <u>
                    <span className="text-capitalize">Learn more</span>
                  </u>
                </Typography>
              </span>
              <span className="ml-1">
                . <br />
                By clicking the 'Start Chatting' button you are providing your cell phone number and
                therefore consenting to receiving text messages and/or phone calls, these messages
                and phone calls may be recorded for quality control purposes.
              </span>
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <ModalFaq open={open} onClose={handleClose} />
      </div>
    </>
  );
}
