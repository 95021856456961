import React, { useState, useEffect } from 'react';
//mui components
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import isObject from 'lodash/isObject';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
//import style
import '../styles/assets/selectField.css';

const getName = (nameKey, item) => {
  if (!nameKey) {
    return item;
  }
  if (Array.isArray(nameKey)) {
    return nameKey.map(key => item[key]).join(' ');
  } else {
    return item[nameKey];
  }
};

const muiStyles = theme => ({
  textField: {
    height: 30,
    fontWeight: 400,
    color: '#ff0000',
  },
  typography: {
    fontSize: 14,
  },
});

const CustomSelectAuto = ({
  sourceArray,
  value,
  nameKey,
  onChange,
  classes,
  valueKey,
  disabled,
  placeholder,
  isLoading,
}) => {
  const [valueLength, setValueLength] = useState(0);
  const [selectedElement, setSelectedElement] = useState({});

  const [typeAble, setTypeAble] = useState(true);

  useEffect(() => {
    if (value) {
      setTypeAble(false);
    } else {
      setTypeAble(true);
    }
  }, [value]);

  const NumResultsHeader = ({ children, ...other }) => {
    const headerRef = React.useRef();
    const countRef = React.useRef();
    const paperRef = React.useRef();
    React.useEffect(() => {
      const numOptions = paperRef.current.querySelectorAll('li[data-option-index]').length;
      countRef.current.innerHTML = numOptions;
      if (numOptions === 1 && typeAble) {
        const renderedValue = paperRef.current.querySelectorAll('li[data-option-index]')[0]
          .innerHTML;
        const renderedEl = sourceArray.find(e => e[nameKey] === renderedValue);
        setSelectedElement(renderedEl);
        onChange(renderedValue);
      }
    });
    return (
      <>
        <div ref={headerRef} style={{ display: 'none' }}>
          Showing <span ref={countRef}></span> of {sourceArray.length}
        </div>
        <Paper {...other} ref={paperRef}>
          {children}
        </Paper>
      </>
    );
  };

  return (
    <React.Fragment>
      <Autocomplete
        options={
          valueLength > 2
            ? selectedElement[valueKey]
              ? sourceArray.filter(e => e === selectedElement)
              : sourceArray
            : []
        }
        value={value === undefined ? '' : value}
        getOptionSelected={option => option[nameKey]}
        getOptionLabel={label => {
          const value = isObject(label)
            ? getName(nameKey, label)
            : getName(nameKey, sourceArray.find(s => s[valueKey] === label) || {});
          // return value === undefined ? '' : value;
          return value ? value : '';
        }}
        onSelect={({ target: { value } }) => {
          setValueLength(value.length);
          const updatedElement = sourceArray.find(s => getName(nameKey, s) === value) || {};
          const updatedValue = updatedElement[valueKey];
          onChange(updatedValue);
          setSelectedElement(updatedElement);
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant="outlined"
              placeholder={isLoading ? 'Loading...' : placeholder}
              onKeyPress={ev => {
                if (value) {
                  ev.preventDefault();
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: isLoading ? (
                  <React.Fragment>
                    {<CircularProgress color="secondary" size={20} />}
                  </React.Fragment>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
            />
          );
        }}
        disabled={disabled || isLoading}
        PaperComponent={NumResultsHeader}
        noOptionsText={null}
        forcePopupIcon={false}
      />
    </React.Fragment>
  );
};

export default withStyles(muiStyles)(CustomSelectAuto);
