import React from 'react';
// Mui
import Typography from '@material-ui/core/Typography';
import UtilErrorBoundary from '../wrappers/WrErrorBoundary';
//styles
import { withStyles } from '@material-ui/core/styles';

//Mui styles
const muiStyles = () => ({
  subTitle: {
    fontSize: 20,
    '@media (max-width:768px)': {
      fontSize: 16, //responsive font size at breakpoint 768px
    },
  },
});

const NavHeaderSubTitle = ({ subtitle, classes }) => {
  return (
    <UtilErrorBoundary>
      <div className="w-100 text-center">
        <Typography className={classes.subTitle}>{subtitle}</Typography>
      </div>
    </UtilErrorBoundary>
  );
};

export default withStyles(muiStyles)(NavHeaderSubTitle);
