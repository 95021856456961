import React from 'react';

// Context
import ContextEndpoint from '../context/ContextEndpoint';

// Dispatchers
import eventBus from '../dispatchers/eventBus';

// Components
// import DataViewListSimple from '../components/DataViewListSimple';
import DataViewTable from '../components/DataViewTable';

// Responsive

export default class ConstrProtectedConversationList extends React.Component {
  static contextType = ContextEndpoint;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      data: [],
    };
  }

  componentDidMount() {
    // Retrieve list of conversations and send to our data dispatch / bus.
    fetch(
      `${this.context.url}/api/conversations/get?source=adminportal&authorization=${localStorage.whoknocked_token}`
    )
      .then(r => {
        return r.json();
      })
      .then(d => {
        eventBus.dispatch('conversationsData', d.data);
        this.setState({ data: d.data });
      })
      .catch(e => {
        window.location.href = '/adminportal';
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loaded: true });
      });
  }

  render() {
    return (
      <div className="root data-constructor data-list-summary">
        {/*<TblList error={this.state.error} loaded={this.state.loaded} />*/}
        {/* <DataViewListSimple error={this.state.error} loaded={this.state.loaded} /> */}
        <DataViewTable conversations={this.state.data} />
      </div>
    );
  }
}
