import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import LoaderSkeleton from './LoaderSkeleton';

const DisplayImage = ({ handleClose, open, imgSrc }) => {
  const [srcFormat, setSrcFormat] = useState('img');
  const src = `https://api.twilio.com${imgSrc.split('.')[0]}`;
  const onClose = () => {
    handleClose();
    setSrcFormat('img');
  };
  return (
    <LoaderSkeleton loaded={true}>
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
        <div>
          {srcFormat === 'img' && (
            <img
              src={src}
              alt=""
              style={{ width: '100%', height: '100%' }}
              id="twilio-img"
              onLoad={() => {
                if (document.getElementById('twilio-img').clientWidth) {
                  setSrcFormat('img');
                }
              }}
              onError={() => {
                setSrcFormat('video');
              }}
            />
          )}
          {srcFormat === 'video' && (
            <video width="100%" height="100%" controls autoPlay>
              <source src={src} />
            </video>
          )}
        </div>
      </Dialog>
    </LoaderSkeleton>
  );
};

export default DisplayImage;
