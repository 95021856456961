import React from 'react';

// Components
import ModalFaq from '../content/ModalFaq';

// Mui
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export default class BtnStaticHowWeProtect extends React.Component {
  constructor(props) {
    super(props);
    this.handleFaqOpen = this.handleFaqOpen.bind(this);
    this.handleFaqClose = this.handleFaqClose.bind(this);
    this.state = {
      showFaq: false,
    };
  }

  // Handle FAQ modal open / close.
  handleFaqOpen() {
    this.setState({ showFaq: true });
  }

  handleFaqClose() {
    this.setState({ showFaq: false });
  }

  render() {
    return (
      <div className="w-100">
        {this.props.variant === 'text' ? (
          <span onClick={this.handleFaqOpen}>
            <Typography variant="body1" className="text-nowrap clickable">
              <u>
                <span className="text-uppercase">H</span>
                <span className="text-lowercase">ow we protect your privacy</span>
              </u>
            </Typography>
          </span>
        ) : (
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            onClick={this.handleFaqOpen}
          >
            How we protect your information
          </Button>
        )}

        <ModalFaq open={this.state.showFaq} onClose={this.handleFaqClose} />
      </div>
    );
  }
}
