import React from 'react';

// Component Helpers
import HelperIconByType from './HelperIconByType';
import HelperTxtByType from './HelperTxtByType';

// Utils
//import UtilErrorBoundary from "./UtilErrorBoundary";
// Mui
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import LoaderSkeleton from './LoaderSkeleton';

/*
  @props:
          labelType={`text`}
          labelFormat={`title`}
          labelVal={this.state.conversation.knockerName}
          displayType={`icon`}
          displayVal={`knocker`}
          displayParams={false}
          size={`lg`}
          color={`dark`}
          clickable={false}
          url={'http://...'}
          tooltip={false}
          loaded={this.state.loaded}
          error={this.state.error}
 */

export default class DisplayChip extends React.Component {
  constructor(props) {
    super(props);

    // Check required props.
    let haveRequired = false;

    if (props.labelFormat && props.displayType) {
      haveRequired = true;
    }

    this.state = {
      displayVal: '',
      displayIcon: '',
      labelVal: props.labelVal | 'NULL',
      loaded: props.loaded,
      error: props.error,
      haveRequired: haveRequired,
    };
  }

  componentDidMount() {
    if (!this.props.displayVal) {
      this.setState({ displayVal: `??` });
    }

    if (!this.props.labelVal) {
      this.setState({ labelVal: `??` });
    }
  }

  render() {
    return (
      <LoaderSkeleton loaded={this.props.loaded}>
        {this.state.haveRequired ? (
          <span
            className={`components ${this.props.cssOverride} ${
              this.props.size === 'lg' ? 'variantLarge' : ''
            } ml-1 ml-xl-2 ml-lg-2 ml-md-1 ml-sm-1`}
          >
            <Chip
              avatar={
                <Avatar>
                  {this.props.displayType === 'avatar'
                    ? this.props.displayVal
                    : HelperIconByType(this.props.displayVal, this.props.displayParams)}
                </Avatar>
              }
              label={HelperTxtByType(
                this.props.labelFormat,
                this.props.labelVal,
                this.props.displayParams
              )}
              color={this.props.color === `dark` ? `secondary` : `inherit`}
              clickable={this.props.clickable}
              href={this.props.url ? this.props.url : false}
              onClick={this.props.onClick}
            />
          </span>
        ) : (
          <span>
            <Chip label="error" color={`inherit`} clickable={false} />
          </span>
        )}
      </LoaderSkeleton>
    );
  }
}
