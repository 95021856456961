import React from "react";

// Constructor/s
import ConstrLandingAdmin from "../constructors/ConstrLandingAdmin";

// Components

export default class ViewLandingAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
    };
  }

  render() {
    return (
      <ConstrLandingAdmin error={this.state.error} loaded={this.state.loaded} />
    );
  }
}
