import React, { useState } from 'react';
//components
import NavBreadcrumbs from './NavBreadcrumbs';
import DisplayChip from './DisplayChip';
import DisplayImage from './DisplayImage';
import DisplayDialog from './DisplayDialog';
//mui data table
import CustomMuiDataTable from './StyledMuiDataTable';
//Mui
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import HelperIconByType from './HelperIconByType';
import Typography from '@material-ui/core/Typography';
import ImageIcon from '@material-ui/icons/Image';
import TableContainer from '@material-ui/core/TableContainer';
import Hidden from '@material-ui/core/Hidden';
import Switch from '@material-ui/core/Switch';
//Helpers
import moment from 'moment';
import HelperTxtByType from './HelperTxtByType';
//styles
import useMediaQuery from '@material-ui/core/useMediaQuery';

const muiStyles = theme => ({
  recordingLink: {
    cursor: 'pointer',
  },
  chipContainer: {
    paddingBottom: 100,
  },
});

const DataViewMessageTable = ({ messages, classes }) => {
  const isMobileSize = useMediaQuery('(max-width: 768px)');
  const [modalOpen, setModalOpen] = useState(false);
  const [chosenRecording, setChosenRecording] = useState('');
  const [selectedMedia, setSelectedMedia] = useState('');
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [dense, setDense] = useState(false);

  const tableData =
    messages.length > 0 &&
    messages.map(m => {
      const obj = {
        messageId: m.id,
        conversationId: m.conversationId,
        serverName: m.serverName,
        serverPhone: HelperTxtByType('phone', m.serverPhone),
        serveeNumber: m.serveeNumber.includes('Reused')
          ? HelperTxtByType('phone', m.serveeNumber.split('-')[1])
          : HelperTxtByType('phone', m.serveeNumber),
        origin: m.originId === 1 ? 'Consumer' : m.originId === 2 ? 'Knocker' : 'System',
        channel: m.channelId === 1 ? 'SMS' : m.channelId === 2 ? 'Voice' : null,
        timeStamp: moment(m.createdAt).format('M/DD/YY kk:mm:ss'),
        message: m.message,
        recording:
          m.url &&
          (m.twilioMeta.RecordingUrl ? (
            <Grid
              container
              alignItems="center"
              onClick={() => {
                setModalOpen(true);
                setChosenRecording(m.url);
              }}
            >
              <IconButton
                color="secondary"
                size="small"
                aria-label="open record"
                className={classes.recordingLink}
              >
                {HelperIconByType(`recording`, false, 'small')}
              </IconButton>
              <Typography className={classes.recordingLink}>Listen to Recording</Typography>
            </Grid>
          ) : m.twilioMeta.Media ? (
            <Grid
              container
              alignItems="center"
              onClick={() => {
                setSelectedMedia(m.url);
                setImgModalOpen(true);
              }}
            >
              <IconButton
                color="secondary"
                size="small"
                aria-label="open media image"
                className={classes.recordingLink}
              >
                <ImageIcon />
              </IconButton>
              <Typography className={classes.recordingLink}>See Image</Typography>
            </Grid>
          ) : null),
      };
      return Object.values(obj);
    });

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: false,
  };

  const columns = [
    {
      name: 'message id',
      label: 'ID',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'conversation id',
      label: 'Conversation ID',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'serverName',
      label: 'Server Name',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: isMobileSize && '180px' } };
        },
      },
    },
    {
      name: 'serverPhone',
      label: 'Server Phone',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: '200px' } };
        },
      },
    },
    {
      name: 'serveeNumber',
      label: 'Consumer Phone',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: '200px' } };
        },
      },
    },
    {
      name: 'origin',
      label: 'Origin',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'channel',
      label: 'Channel',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000' } };
        },
      },
    },
    {
      name: 'timestamp',
      label: 'Timestamp',
      options: {
        filter: true,
        setCellProps: () => ({ style: { minWidth: '180px' } }),
        filterType: 'dropdown',
        responsive: 'standard',
      },
    },
    {
      name: 'message',
      label: 'Message',
      options: {
        setCellHeaderProps: () => {
          return { style: { color: '#000', minWidth: isMobileSize && '700px' } };
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        setCellProps: () => ({ style: { minWidth: '200px' } }),
        filter: false,
        sort: false,
      },
    },
  ];

  const toggleDense = () => {
    setDense(!dense);
  };

  return (
    <div className="root w-100">
      {/* Header */}
      <div className="data-header d-flex flex-row align-items-center justify-content-start">
        <NavBreadcrumbs
          currentStep={`list`}
          previousSteps={['Conversations']}
          loaded={messages.length > 0}
          error={false}
        />
      </div>

      {/* Results */}
      <div className="data-container-list data-container-list-summary w-100 py-1 py-xl-3 py-lg-2 py-md-2 py-sm-1">
        <TableContainer>
          {messages.length > 0 && (
            <CustomMuiDataTable
              data={tableData}
              title={'Messages'}
              columns={columns}
              options={options}
            />
          )}
        </TableContainer>
      </div>

      {/* Chips */}
      <div className={classes.chipContainer}>
        <div className="data-footer w-100 d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center justify-content-start">
            {/* @todo: Move to component */}
            <Hidden xlDown>
              <Switch checked={dense} onChange={toggleDense} name="dense" />
            </Hidden>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end">
            <DisplayChip
              labelType={`text`}
              labelFormat={`static`}
              labelVal={`Messages`}
              displayType={`avatar`}
              displayVal={messages.length}
              color={`dark`}
              clickable={false}
              loaded={messages.length > 0}
              error={false}
            />
          </div>
        </div>
      </div>

      <div>
        <DisplayDialog
          open={modalOpen}
          RecordingUrl={chosenRecording}
          title={`Call Recording`}
          updateActive={() => setModalOpen(false)}
        />
      </div>
      <div>
        <DisplayImage
          open={imgModalOpen}
          handleClose={() => {
            setImgModalOpen(false);
            setSelectedMedia('');
          }}
          imgSrc={selectedMedia}
        />
      </div>
    </div>
  );
};

export default withStyles(muiStyles)(DataViewMessageTable);
