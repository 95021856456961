import { titleCase } from "title-case";

export default function getPrettyTxt(str) {
  let finalStr = str;

  if (str) {
    try {
      finalStr = finalStr.trim();
      finalStr = titleCase(finalStr);
    } catch {
      return finalStr;
    }
  }

  return finalStr;
}
