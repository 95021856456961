import React from 'react';
import PropTypes from 'prop-types';

// UI components.
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

export default function ModalTerms(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(true);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="terms" open={open} maxWidth="md" scroll="paper">
      <DialogTitle id="terms-dialog-title">
        <Typography variant="h4" className="py-4">
          <span className="text-dark font-weight-bold">Terms and Conditions</span>
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <div className="text-dark">
            <div className="pb-3">
              <span className="font-weight-bold">Last updated:</span>{' '}
              <span className="font-italic">March 27, 2020</span>
            </div>
            <div className="pb-3">
              <span className="font-weight-bold font-italic">
                Please read these terms and conditions carefully before using Our Service.
              </span>
            </div>
            <div className="pb-3">Interpretation and Definitions</div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Interpretation</span>
            </Typography>
            <div className="pb-3">
              The words of which the initial letter is capitalized have meanings defined under the
              following conditions.
            </div>
            <div className="pb-3">
              The following definitions shall have the same meaning regardless of whether they
              appear in singular or in plural.
            </div>
            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Definitions</span>
            </Typography>
            <div className="pb-3">
              <div className="pb-3">For the purposes of these Terms and Conditions:</div>
              <ul>
                <li>
                  <span className="font-weight-bold">Application</span> means the software program
                  provided by the Company downloaded by You on any electronic device, named
                  WhoKnocked
                </li>
                <li>
                  <span className="font-weight-bold">Application Store</span> means the digital
                  distribution service operated and developed by Apple Inc. (Apple App Store) or
                  Google Inc. (Google Play Store) in which the Application has been downloaded.
                </li>
                <li>
                  <span className="font-weight-bold">Affiliate</span> means an entity that controls,
                  is controlled by or is under common control with a party, where "control" means
                  ownership of 50% or more of the shares, equity interest or other securities
                  entitled to vote for election of directors or other managing authority.
                </li>
                <li>
                  <span className="font-weight-bold">Account</span> means a unique account created
                  for You to access our Service or parts of our Service.
                </li>
                <li>
                  <span className="font-weight-bold">Company </span>(referred to as either "the
                  Company", "We", "Us" or "Our" in this Agreement) refers to Executive Process LLC,
                  9210 S. Western Ave Suite A-3, Oklahoma City, OK 73139.
                </li>
                <li>
                  <span className="font-weight-bold">Country</span> refers to: Oklahoma, United
                  States
                </li>
                <li>
                  <span className="font-weight-bold">Device</span> means any device that can access
                  the Service such as a computer, a cellphone or a digital tablet.
                </li>
                <li>
                  <span className="font-weight-bold">Feedback</span> means feedback, innovations or
                  suggestions sent by You regarding the attributes, performance or features of our
                  Service.
                </li>
                <li>
                  <span className="font-weight-bold">Service</span> refers to the Application or the
                  Website or both.
                </li>
                <li>
                  <span className="font-weight-bold">Terms and Conditions</span> (also referred as
                  "Terms") mean these Terms and Conditions that form the entire agreement between
                  You and the Company regarding the use of the Service.
                </li>
                <li>
                  <span className="font-weight-bold">Third-party Social Media Service</span> means
                  any services or content (including data, information, products or services)
                  provided by a third-party that may be displayed, included or made available by the
                  Service.
                </li>
                <li>
                  <span className="font-weight-bold">Website</span> refers to WhoKnocked, accessible
                  from www.whoknocked.com
                </li>
                <li>
                  <span className="font-weight-bold">You</span> means the individual accessing or
                  using the Service, or the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </li>
              </ul>
              <Typography variant="h6" gutterBottom>
                Acknowledgement
              </Typography>
              <div className="pb-3">
                These are the Terms and Conditions governing the use of this Service and the
                agreement that operates between You and the Company. These Terms and Conditions set
                out the rights and obligations of all users regarding the use of the Service.
              </div>
              <div className="pb-3">
                Your access to and use of the Service is conditioned on Your acceptance of and
                compliance with these Terms and Conditions. These Terms and Conditions apply to all
                visitors, users and others who access or use the Service.
              </div>
              <div className="pb-3">
                By accessing or using the Service You agree to be bound by these Terms and
                Conditions. If You disagree with any part of these Terms and Conditions then You may
                not access the Service.
              </div>
              <div className="pb-3">
                Your access to and use of the Service is also conditioned on Your acceptance of and
                compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our
                policies and procedures on the collection, use and disclosure of Your personal
                information when You use the Application or the Website and tells You about Your
                privacy rights and how the law protects You. Please read Our Privacy Policy
                carefully before using Our Service.
              </div>
              <Typography variant="h6" gutterBottom>
                User Accounts
              </Typography>
              <div className="pb-3">
                When You create an account with Us, You must provide Us information that is
                accurate, complete, and current at all times. Failure to do so constitutes a breach
                of the Terms, which may result in immediate termination of Your account on Our
                Service.
              </div>
              <div className="pb-3">
                You are responsible for safeguarding the password that You use to access the Service
                and for any activities or actions under Your password, whether Your password is with
                Our Service or a Third-Party Social Media Service.
              </div>
              <div className="pb-3">
                You agree not to disclose Your password to any third party. You must notify Us
                immediately upon becoming aware of any breach of security or unauthorized use of
                Your account.
              </div>
              <div className="pb-3">
                You may not use as a username the name of another person or entity or that is not
                lawfully available for use, a name or trademark that is subject to any rights of
                another person or entity other than You without appropriate authorization, or a name
                that is otherwise offensive, vulgar or obscene.
              </div>
              <Typography variant="h6" gutterBottom>
                Intellectual Property
              </Typography>
              <div className="pb-3">
                The Service and its original content (excluding Content provided by You or other
                users), features and functionality are and will remain the exclusive property of the
                Company and its licensors.
              </div>
              <div className="pb-3">
                The Service is protected by copyright, trademark, and other laws of both the Country
                and foreign countries.
              </div>
              <div className="pb-3">
                Our trademarks and trade dress may not be used in connection with any product or
                service without the prior written consent of the Company.
              </div>
              <Typography variant="h6" gutterBottom>
                Your Feedback to Us
              </Typography>
              <div className="pb-3">
                You assign all rights, title and interest in any Feedback You provide the Company.
                If for any reason such assignment is ineffective, You agree to grant the Company a
                non-exclusive, perpetual, irrevocable, royalty free, worldwide right and licence to
                use, reproduce, disclose, sub-licence, distribute, modify and exploit such Feedback
                without restriction.
              </div>
              <Typography variant="h6" gutterBottom>
                Links to Other Websites
              </Typography>
              <div className="pb-3">
                Our Service may contain links to third-party web sites or services that are not
                owned or controlled by the Company.
              </div>
              <div className="pb-3">
                The Company has no control over, and assumes no responsibility for, the content,
                privacy policies, or practices of any third party web sites or services. You further
                acknowledge and agree that the Company shall not be responsible or liable, directly
                or indirectly, for any damage or loss caused or alleged to be caused by or in
                connection with the use of or reliance on any such content, goods or services
                available on or through any such web sites or services.
              </div>
              <div className="pb-3">
                We strongly advise You to read the terms and conditions and privacy policies of any
                third-party web sites or services that You visit.
              </div>
              <Typography variant="h6" gutterBottom>
                Termination
              </Typography>
              <div className="pb-3">
                We may terminate or suspend Your Account immediately, without prior notice or
                liability, for any reason whatsoever, including without limitation if You breach
                these Terms and Conditions.
              </div>
              <div className="pb-3">
                Upon termination, Your right to use the Service will cease immediately. If You wish
                to terminate Your Account, You may simply discontinue using the Service.
              </div>
              <Typography variant="h6" gutterBottom>
                Limitation of Liability
              </Typography>
              <div className="pb-3">
                Notwithstanding any damages that You might incur, the entire liability of the
                Company and any of its suppliers under any provision of this Terms and Your
                exclusive remedy for all of the foregoing shall be limited to the amount actually
                paid by You through the Service or 100 USD if You haven't purchased anything through
                the Service.
              </div>
              <div className="pb-3">
                To the maximum extent permitted by applicable law, in no event shall the Company or
                its suppliers be liable for any special, inconversationIdental, indirect, or
                consequential damages whatsoever (including, but not limited to, damages for loss of
                profits, loss of data or other information, for business interruption, for personal
                injury, loss of privacy arising out of or in any way related to the use of or
                inability to use the Service, third-party software and/or third-party hardware used
                with the Service, or otherwise in connection with any provision of this Terms), even
                if the Company or any supplier has been advised of the possibility of such damages
                and even if the remedy fails of its essential purpose.
              </div>
              <div className="pb-3">
                Some states do not allow the exclusion of implied warranties or limitation of
                liability for inconversationIdental or consequential damages, which means that some
                of the above limitations may not apply. In these states, each party's liability will
                be limited to the greatest extent permitted by law.
              </div>
              <Typography variant="h6" gutterBottom>
                "AS IS" and "AS AVAILABLE" Disclaimer
              </Typography>
              <div className="pb-3">
                The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and
                defects without warranty of any kind. To the maximum extent permitted under
                applicable law, the Company, on its own behalf and on behalf of its Affiliates and
                its and their respective licensors and service providers, expressly disclaims all
                warranties, whether express, implied, statutory or otherwise, with respect to the
                Service, including all implied warranties of merchantability, fitness for a
                particular purpose, title and non-infringement, and warranties that may arise out of
                course of dealing, course of performance, usage or trade practice. Without
                limitation to the foregoing, the Company provides no warranty or undertaking, and
                makes no representation of any kind that the Service will meet Your requirements,
                achieve any intended results, be compatible or work with any other software,
                applications, systems or services, operate without interruption, meet any
                performance or reliability standards or be error free or that any errors or defects
                can or will be corrected.
              </div>
              <div className="pb-3">
                Without limiting the foregoing, neither the Company nor any of the company's
                provider makes any representation or warranty of any kind, express or implied: (i)
                as to the operation or availability of the Service, or the information, content, and
                materials or products included thereon; (ii) that the Service will be uninterrupted
                or error-free; (iii) as to the accuracy, reliability, or currency of any information
                or content provided through the Service; or (iv) that the Service, its servers, the
                content, or e-mails sent from or on behalf of the Company are free of viruses,
                scripts, trojan horses, worms, malware, timebombs or other harmful components.
              </div>
              <div className="pb-3">
                Some jurisdictions do not allow the exclusion of certain types of warranties or
                limitations on applicable statutory rights of a consumer, so some or all of the
                above exclusions and limitations may not apply to You. But in such a case the
                exclusions and limitations set forth in this section shall be applied to the
                greatest extent enforceable under applicable law.
              </div>
              <Typography variant="h6" gutterBottom>
                Governing Law
              </Typography>
              <div className="pb-3">
                The laws of the Country, excluding its conflicts of law rules, shall govern this
                Terms and Your use of the Service. Your use of the Application may also be subject
                to other local, state, national, or international laws.
              </div>
              <Typography variant="h6" gutterBottom>
                Disputes Resolution
              </Typography>
              <div className="pb-3">
                If You have any concern or dispute about the Service, You agree to first try to
                resolve the dispute informally by contacting the Company.
              </div>
              <Typography variant="h6" gutterBottom>
                For European Union (EU) Users
              </Typography>
              <div className="pb-3">
                If You are a European Union consumer, you will benefit from any mandatory provisions
                of the law of the country in which you are resident in.
              </div>
              <Typography variant="h6" gutterBottom>
                United States Legal Compliance
              </Typography>
              <div className="pb-3">
                You represent and warrant that (i) You are not located in a country that is subject
                to the United States government embargo, or that has been designated by the United
                States government as a “terrorist supporting” country, and (ii) You are not listed
                on any United States government list of prohibited or restricted parties.
              </div>
              <div className="pb-3">Severability and Waiver</div>
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Severability</span>
            </Typography>
            <div className="pb-3">
              If any provision of these Terms is held to be unenforceable or invalid, such provision
              will be changed and interpreted to accomplish the objectives of such provision to the
              greatest extent possible under applicable law and the remaining provisions will
              continue in full force and effect.
            </div>

            <Typography variant="h4" gutterBottom>
              <span className="font-weight-bold">Waiver</span>
            </Typography>
            <div className="pb-3">
              <div className="pb-3">
                Except as provided herein, the failure to exercise a right or to require performance
                of an obligation under this Terms shall not effect a party's ability to exercise
                such right or require such performance at any time thereafter nor shall be the
                waiver of a breach constitute a waiver of any subsequent breach.
              </div>
              <Typography variant="h6" gutterBottom>
                Translation Interpretation
              </Typography>
              <div className="pb-3">
                These Terms and Conditions may have been translated if We have made them available
                to You on our Service.
              </div>
              <div className="pb-3">
                You agree that the original English text shall prevail in the case of a dispute.
              </div>
              <Typography variant="h6" gutterBottom>
                Changes to These Terms and Conditions
              </Typography>
              <div className="pb-3">
                We reserve the right, at Our sole discretion, to modify or replace these Terms at
                any time. If a revision is material We will make reasonable efforts to provide at
                least 30 days' notice prior to any new terms taking effect. What constitutes a
                material change will be determined at Our sole discretion.
              </div>
              <div className="pb-3">
                By continuing to access or use Our Service after those revisions become effective,
                You agree to be bound by the revised terms. If You do not agree to the new terms, in
                whole or in part, please stop using the website and the Service.
              </div>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <div className="pb-3">
                If you have any questions about these Terms and Conditions, You can contact us By
                visiting this page on our website: www.whoknocked.com
              </div>
            </div>
          </div>
        </Typography>
        <Divider />
      </DialogContent>

      <DialogActions>
        <div className="p-4">
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ModalTerms.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
