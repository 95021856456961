import React from 'react';

// Context
import ContextEndpoint from '../context/ContextEndpoint';

// Authentication State.
// import Cookies from 'universal-cookie';

// Components
import SnackbarError from './SnackbarError';

// Mui
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class FormAuthenticate extends React.Component {
  static contextType = ContextEndpoint;

  constructor(props) {
    super(props);
    this.state = {
      adminUserName: '',
      adminPassword: '',
      status: '',
      openSb: false,
      formError: false,
      loaded: props.loaded,
      error: props.error,
    };
    this.handleAdminUserName = this.handleAdminUserName.bind(this);
    this.handleAdminPassword = this.handleAdminPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateSnackbar = this.updateSnackbar.bind(this);
  }

  // Pass changes to status of conversation up from the "end conversation" button.
  updateSnackbar = () => {
    this.setState({ openSb: false });
  };

  // Login form handlers.
  handleAdminUserName = event => {
    this.setState({ adminUserName: event.target.value });
  };

  handleAdminPassword = event => {
    this.setState({ adminPassword: event.target.value });
  };

  handleSubmit = () => {
    this.setState({ loaded: false });

    // Call endpoint to check username/password.
    fetch(
      `${this.context.url}/api/admin/get?u=${encodeURIComponent(
        this.state.adminUserName
      )}&p=${encodeURIComponent(this.state.adminPassword)}&authorization=${
        localStorage.whoknocked_general_token
      }`
    )
      .then(r => r.json())
      .then(d => {
        if (d.data.isAuthenticated) {
          // Set authentication state
          localStorage.setItem('whoknocked_token', d.data.token);

          // const cookies = new Cookies();
          // cookies.set('adminAuth', true);

          // redirect user to adminportal/list
          window.location.href = '/adminportal/list';
        } else {
          if (localStorage.whoknocked_token) {
            localStorage.removeItem('whoknocked_token');
          }
          this.setState({ openSb: true });
          this.setState({ formError: true });
        }
      })
      .catch(e => {
        // Handle state if error.
        this.setState({ error: e.toString() });
        this.setState({ openSb: true });
        this.setState({ formError: true });
      })
      .finally(() => {
        this.setState({ loaded: true });
      });
  };

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    return (
      <div className="component component-form root w-100">
        {!this.state.loaded ? (
          <div className="w-100 h-100 fixed-top pt-4 mt-4 d-flex flex-row justify-content-center align-items-center">
            <CircularProgress color="primary" size={50} />
          </div>
        ) : (
          <div className="form-container form-authenticate w-100 py-4 my-4">
            <Grid container direction="column" spacing={2} justify="flex-start" alignItems="center">
              <Grid item xs={12} sm={12} md={8} lg={5} xl={4}>
                <TextField
                  id="adminUserName"
                  label="Username"
                  variant="filled"
                  size="large"
                  color="secondary"
                  fullWidth
                  required
                  onChange={this.handleAdminUserName}
                  error={this.state.formError}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={5} xl={4}>
                <TextField
                  id="adminPassword"
                  label="Password"
                  variant="filled"
                  color="secondary"
                  type="password"
                  size="large"
                  fullWidth
                  required
                  onChange={this.handleAdminPassword}
                  error={this.state.formError}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={5} xl={4} className="pt-1 mt-1">
                <div className="d-flex flex-row justify-content-center">
                  <div className="mt-2 w-100">
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      disableElevation
                      fullWidth
                      onClick={this.handleSubmit}
                    >
                      <Typography variant="h6" className="p-1">
                        Login
                      </Typography>
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>

            <SnackbarError
              open={this.state.openSb}
              errorMsg={`Login failed. Please try again.`}
              updateSnackbar={this.updateSnackbar}
            />
          </div>
        )}
      </div>
    );
  }
}
