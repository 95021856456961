import React, { useState, useEffect, useContext } from 'react';

// Context
import ContextEndpoint from '../context/ContextEndpoint';
//components
import DataViewMessageTable from '../components/DataViewMessageTable';

const ConstrProtectedMessageList = () => {
  const context = useContext(ContextEndpoint);
  const { url } = context;
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    fetch(
      `${url}/api/messages/get?source=adminportal&authorization=${localStorage.whoknocked_token}`
    )
      .then(r => {
        return r.json();
      })
      .then(d => setMessages(d.data))
      .catch(e => {
        window.location.href = '/adminportal';
      });
  }, [url]);
  return (
    <div>
      <DataViewMessageTable messages={messages} />
    </div>
  );
};

export default ConstrProtectedMessageList;
