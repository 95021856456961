import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

// State
// import Cookies from 'universal-cookie';

/* *** *** *** *** Views *** *** *** *** */
/* *** *** *** --- public --- *** *** *** */
import ViewLandingSlug from './front-end/views/ViewLandingSlug';
import ViewLandingNoSlug from './front-end/views/ViewLandingNoSlug';
import ViewPublicConnected from './front-end/views/ViewPublicConnected';
import ViewPublicChat from './front-end/views/ViewPublicChat';
import ViewPublicMoreInfo from './front-end/views/ViewPublicMoreInfo';
import ViewLandingAdmin from './front-end/views/ViewLandingAdmin';

/* *** *** *** --- protected --- *** *** *** */
import ViewProtectedConversationList from './front-end/views/ViewProtectedConversationList';
import ViewProtectedConversationDetails from './front-end/views/ViewProtectedConversationDetails';
import ViewProtectedMessageList from './front-end/views/ViewProtectedMessageList';
/* *** *** *** --- errors & exceptions --- *** *** *** */
import ViewPublicError from './front-end/views/ViewPublicError';
import ViewPublicBadSlug from './front-end/views/ViewPublicBadSlug';

/* *** *** *** --- wrappers --- *** *** *** */
import WrViewContainer from './front-end/wrappers/WrViewContainer';

// A wrapper for <Route> that redirects to the login
// screen if admin is not yet authenticated.
const PrivateRoute = ({ component: Component, ...Rest }) => (
  <Route
    {...Rest}
    render={props =>
      localStorage.getItem('whoknocked_token') ? (
        <Component {...props} />
      ) : (
        <Redirect to="/adminportal" />
      )
    }
  />
);

const hideBotPathNameLists = [
  '/adminportal',
  '/adminportal/list',
  '/adminportal/messages',
  '/adminportal/d',
];
const hideBot =
  hideBotPathNameLists.includes(window.location.pathname) ||
  window.location.pathname.includes(hideBotPathNameLists[3]);

export default function App() {
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + process.env.REACT_APP_INTERCOM_KEY;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
  !hideBot &&
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_KEY,
    });
  useEffect(() => {
    fetch(`/api/accessToken/get`)
      .then(response => response.json())
      .then(result => {
        if (result.code === 200) {
          localStorage.setItem('whoknocked_general_token', result.token);
        }
      })
      .catch(err => console.error('Error===>', err));
  }, []);

  return (
    <Router>
      <WrViewContainer>
        <Switch>
          {/* Protected */}
          <PrivateRoute path="/adminportal/list" component={ViewProtectedConversationList} />
          <PrivateRoute path="/adminportal/messages" component={ViewProtectedMessageList} />
          <PrivateRoute path="/adminportal/d/:id/" component={ViewProtectedConversationDetails} />
          <Route exact path="/adminportal" component={ViewLandingAdmin} />

          {/* Errors & Exceptions */}
          <Route exact path="/error" component={ViewPublicError} />
          <Route exact path="/oops" component={ViewPublicBadSlug} />

          {/* Public */}
          <Route exact path="/" component={ViewLandingNoSlug} />
          <Route exact path="/:slug" component={ViewLandingSlug} />
          <Route exact path="/:slug/connected" component={ViewPublicConnected} />
          <Route exact path="/:slug/more-info" component={ViewPublicMoreInfo} />
          <Route exact path="/:slug/chat" component={ViewPublicChat} />
        </Switch>
      </WrViewContainer>
    </Router>
  );
}
