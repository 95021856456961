import React from 'react';
import PropTypes from 'prop-types';

// UI components.
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

export default function ModalMission(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="mission"
      open={open}
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle id="mission-dialog-title">
        <Typography variant="h4" className="py-4">
          <span className="text-dark font-weight-bold">Mission Statement</span>
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <div className="text-dark">
            <div className="py-5">
              WhoKnocked aims to bring security and transparency to the service of process (process
              serving). Through technology, WhoKnocked offers YOU the ability to have a say on when
              and where you are served. This makes getting served safer and eliminates the chance of
              being served in an undesirable setting.
            </div>
          </div>
        </Typography>
        <Divider />
      </DialogContent>

      <DialogActions>
        <div className="p-4">
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ModalMission.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
