import React from 'react';

// Dispatchers
// Mui
import Typography from '@material-ui/core/Typography';
import UtilErrorBoundary from '../wrappers/WrErrorBoundary';
//styles
import { withStyles } from '@material-ui/core/styles';

//Mui styles
const muiStyles = () => ({
  title: {
    fontSize: 40,
    '@media (max-width:768px)': {
      fontSize: 32, //responsive font size at breakpoint 768px
    },
  },
});

const NavHeaderTitle = ({ title, classes }) => {
  return (
    <UtilErrorBoundary>
      {title && (
        <div>
          <Typography className={classes.title} align="center" gutterBottom>
            {title}
          </Typography>
        </div>
      )}
    </UtilErrorBoundary>
  );
};

export default withStyles(muiStyles)(NavHeaderTitle);
