import React from 'react';

// Context
import ContextEndpoint from '../context/ContextEndpoint';

// Dispatchers
import eventBus from '../dispatchers/eventBus';

// Components
// import DataViewListDetailed from '../components/DataViewListDetailed';
import DataViewListTable from '../components/DataViewListTable';

// Responsive

export default class ConstrProtectedConversationDetails extends React.Component {
  static contextType = ContextEndpoint;

  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      loaded: false,
      error: false,
      messagesData: [],
      conversation: {},
    };
    this.getConversationData = this.getConversationData.bind(this);
  }

  getConversationData = () => {
    // Retrieve list of messages and send to our data dispatch / bus.
    fetch(
      `${this.context.url}/api/conversation/get?conversationId=${this.props.conversationId}&authorization=${localStorage.whoknocked_token}`
    )
      .then(r => {
        return r.json();
      })
      .then(d => {
        //this.setState({ messages: d.data });
        this.setState({ conversation: d.data });
        eventBus.dispatch('conversationMetaData', d.data);
      })
      .catch(e => {
        window.location.href = '/adminportal';
        this.setState({ error: true });
      });
  };

  componentDidMount() {
    this.getConversationData();
    // Retrieve list of messages and send to our data dispatch / bus.
    fetch(
      `${this.context.url}/api/conversation/messages/get?conversationId=${this.props.conversationId}&authorization=${localStorage.whoknocked_token}`
    )
      .then(r => {
        return r.json();
      })
      .then(d => {
        //this.setState({ messages: d.data });
        this.setState({ messagesData: d.data });
        eventBus.dispatch('messagesData', d.data);
      })
      .catch(e => {
        window.location.href = '/adminportal';
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loaded: true });
      });
  }

  render() {
    return (
      <div className="root data-constructor">
        {/* <DataViewListDetailed
          conversationId={this.props.id}
          error={this.state.error}
          loaded={this.state.loaded}
        /> */}
        <DataViewListTable
          messages={this.state.messagesData}
          conversationId={this.props.id}
          conversation={this.state.conversation}
          context={this.context.url}
          getConversationData={this.getConversationData}
        />
      </div>
    );
  }
}
