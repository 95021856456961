import React from "react";

// Mui
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

export default function BtnHowWeProtect(props) {
  return (
    <div className="w-100 text-center d-flex flex-column justify-content-center align-items-center">
      <Typography variant="caption">Or, go back</Typography>
      <Button
        size="large"
        variant="contained"
        color="secondary"
        disableElevation
        fullWidth
        href={`/${props.slug}`}
      >
        Enter your phone number
      </Button>
    </div>
  );
}
